import React, { useState, useContext } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import ajaxService from '../../../services/ajax-service';
import { CircularProgress } from '@mui/material';
import { NotificationBar } from '../../../components';
import { AuthContext } from '../../../AuthContext';

const defaultTheme = createTheme();

const Login = () => {

    const { login } = useContext(AuthContext);

    const navigate = useNavigate()

    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });
    const [errors, setErrors] = useState({});

    // for notification
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    // loading
    const [loading, setLoading] = useState(false);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        // Clear previous error message when user starts typing
        setErrors({
            ...errors,
            [name]: '',
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        // Validation rules
        const { email, password } = formData;
        const newErrors = {};
        if (!email.trim()) {
            newErrors.email = 'Email Address is required';
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            newErrors.email = 'Email Address is invalid';
        }
        if (!password.trim()) {
            newErrors.password = 'Password is required';
        }

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
        } else {
            setLoading(true)
            const response = await ajaxService.getAccessToken(formData);
            setLoading(false)

            if (!response.success || (!response.verified && (!response.email_verified || !response.phone_verified))) {
                setMessage(response.message);
                setOpen(true);
            } else {
                login(response.user,response.access_token,response.expires_at);
                navigate('/');
            }
        }
    };

    return (
        <>
            <ThemeProvider theme={defaultTheme}>
                <Grid className='poppins my-7' container component="main" sx={{ display: 'flex', justifyContent: 'center' }}>
                    <CssBaseline />
                    <Grid
                        item
                        xs={false}
                        sm={4}
                        md={4}
                        sx={{
                            backgroundImage: 'url(https://source.unsplash.com/random?wallpapers)',
                            backgroundRepeat: 'no-repeat',
                            backgroundColor: (t) =>
                                t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                        }}
                    />
                    <Grid item xs={12} sm={8} md={4} component={Paper} elevation={6} square>
                        <Box
                            sx={{
                                my: 15,
                                mx: 4,
                                display: 'flex',
                                flexDirection: 'column',
                                // alignItems: 'center',
                            }}
                        >
                            <Typography component="h1" variant="h6" style={{ color: '#b5b5bf' }}>
                                LOGIN WITH
                            </Typography>
                            <Typography component="h1" variant="h4" className='poppins' style={{ color: '#2858a3', fontWeight: 900 }}>
                                BURAQ STAR
                            </Typography>
                            <Typography component="h1" variant="h6" style={{ color: '#b5b5bf' }}>
                                ACCOUNT
                            </Typography>
                            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                                <TextField
                                    autoFocus
                                    fullWidth
                                    margin="normal"
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    autoComplete="email"
                                    error={!!errors.email}
                                    helperText={errors.email}
                                    value={formData.email}
                                    onChange={handleChange}
                                />
                                <TextField
                                    fullWidth
                                    margin="normal"
                                    name="password"
                                    label="Password"
                                    type="password"
                                    id="password"
                                    autoComplete="current-password"
                                    error={!!errors.password}
                                    helperText={errors.password}
                                    value={formData.password}
                                    onChange={handleChange}
                                />
                                <Button
                                    className='poppins bg-[#2858a3] w-[30%]'
                                    style={{ height: '50px', textTransform: 'capitalize' }}
                                    // fullWidth
                                    type="submit"
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                    disabled={loading}
                                >
                                    {loading && <CircularProgress size={25} className="text-white" />}
                                    {!loading && "Login"}
                                </Button>
                                <Grid container>
                                    <Grid item xs>
                                        <Link href="#" variant="body2">
                                            Forgot password?
                                        </Link>
                                    </Grid>
                                    <Grid item>
                                        <Link className='cursor-pointer' onClick={() => navigate('/user/registration')} variant="body2">
                                            {"Don't have an account? Signup"}
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </ThemeProvider>

            {/* notification */}
            <NotificationBar
                open={open}
                setOpen={setOpen}
                type="error"
                message={message}
            />
        </>
    );
}

export default Login