import React, { useEffect, useState } from "react";
import { Box, ButtonBase, Typography } from "@mui/material";
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper/modules";

import { useNavigate } from "react-router-dom";
import { BRANDS } from "../../constants";
import ajaxService from "../../services/ajax-service";

const BrandBanner = () => {

  const navigate = useNavigate();

  const [brands, setBrands] = useState([])

  const loadBrands = async () => {
    // const { success, data } = await ajaxService.get('/all-brands');
    // if (success) {
    //   setBrands(data)
    // }
  }

  const banners = [
    {
      id: brands.find(i => i.name === BRANDS.BURAQ)?.id ?? 0,
      banner: require("../../assets/buraqbg.png"),
      logo: require("../../assets/whiteburaq.png"),
      logo1: require("../../assets/buraq-banner1.png"),
      logo2: require("../../assets/buraq-banner2.png")
    },
    {
      id: brands.find(i => i.name === BRANDS.CAVIL)?.id ?? 0,
      banner: require("../../assets/cavilbg.png"),
      logo: require("../../assets/whitecavil.png"),
      logo1: require("../../assets/cavil-banner1.png"),
      logo2: require("../../assets/cavil-banner2.png")
    },
    {
      id: brands.find(i => i.name === BRANDS.NOVEX)?.id ?? 0,
      banner: require("../../assets/novexbg.jpg"),
      logo: require("../../assets/novex.jpg"),
      logo1: require("../../assets/brand1.png"),
      logo2: require("../../assets/brand2.png")
    },
    {
      id: brands.find(i => i.name === BRANDS.ZILCO)?.id ?? 0,
      banner: require("../../assets/zilcobg.png"),
      logo: require("../../assets/whitezilco.png"),
      logo1: require("../../assets/zilco-banner1.png"),
      logo2: require("../../assets/zilco-banner2.png")
    },
  ];

  useEffect(() => {
    loadBrands();
  }, [])

  return (
    <>
      <Box className="w-full h-fit sm:h-[26vh] px-5 md:px-[4%] lg:px-[8%] mt-10">
        <Swiper
          className="h-[160px] brand-banner-swiper"
          modules={[Pagination, Autoplay]}
          pagination={{ clickable: true }}
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
          style={{
            "--swiper-pagination-color": "#fff",
            "--swiper-pagination-bullet-inactive-color": "transparent",
            "--swiper-pagination-bullet-inactive-opacity": "1",
            "--swiper-pagination-bullet-size": "16px",
            "--swiper-pagination-bullet-horizontal-gap": "6px",
          }}
        >
          {banners.map((val, i) => (
            <SwiperSlide key={i}>
              <ButtonBase onClick={() => navigate('/brand?brand_id=' + val.id)}
                className="w-full h-full rounded-lg bg-no-repeat bg-cover bg-center flex flex-col sm:flex-row gap-y-6 py-4 sm:py-0 sm:gap-y-0 justify-around items-center"
                style={{ backgroundImage: `url(${val.banner})` }}
              >
                <img src={val.logo} className="h-16 w-auto" alt="brand logo" />
                <Typography className="poppins text-2xl sm:text-3xl tex-center px-2 font-semibold text-white">
                  Avail Supreme Deals
                </Typography>
                <Box className="flex items-center">
                  <img src={val.logo1} className="h-[130px] w-auto" alt="brand logo" />
                  <img src={val.logo2} className="h-[130px] w-auto" alt="brand logo" />
                </Box>
              </ButtonBase>
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>

      {/* Old Banner */}

      {/* <Box className="w-full h-fit sm:h-[26vh] px-5 md:px-[4%] lg:px-[8%] mb-10">
        <Box
          className="w-full h-full rounded-lg bg-no-repeat bg-cover bg-center flex flex-col sm:flex-row gap-y-6 py-4 sm:py-0 sm:gap-y-0 justify-around items-center"
          sx={{ backgroundImage: `url(${novexbg})` }}
        >
          <ButtonBase onClick={() => navigate('/brand?brand_id=' + brandID)}>
            <img src={novexlogo} className="h-16 w-auto" alt="brand logo" />
          </ButtonBase>
          <Typography className="poppins text-2xl sm:text-3xl tex-center px-2 font-semibold text-white">
            Avail Supreme Deals
          </Typography>
          <Box className="flex items-center">
            <img src={brand1} className="h-24 w-auto" alt="brand logo" />
            <img src={brand2} className="h-24 w-auto" alt="brand logo" />
          </Box>
        </Box>
      </Box> */}
    </>
  );
};

export default BrandBanner;
