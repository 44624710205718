import React, { useEffect, useState, useContext, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { routes as AppRoute } from "./../../utils";
import {
  Badge,
  Box,
  Divider,
  Drawer,
  IconButton,
  ListItem,
  ListItemButton,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import MenuIcon from "@mui/icons-material/Menu";
import { useCart } from "react-use-cart";
import logo from "./../../assets/burraqstar.svg";
import { routes } from "../../data";
import ajaxService from "../../services/ajax-service";
import { CATEGORIES, ImageURL } from "../../constants";
import { AuthContext } from "../../AuthContext";
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';


const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -3,
    top: 13,
    border: `2px solid white`,
    padding: "0 4px",
  },
}));

const HeaderBar = () => {
  const { logout, isLoggedIn } = useContext(AuthContext);
  const { totalUniqueItems } = useCart();
  const [searchOpen, setSearchOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [open, setOpen] = useState(false);
  const [products, setProducts] = useState([]);

  const navigate = useNavigate();
  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));

  const handleLogout = useCallback(async () => {
    await ajaxService.get("/auth/logout", 0, true);
    localStorage.clear();
    logout();
    navigate("/");
  }, [logout, navigate]);

  const handleInputBlur = useCallback(() => {
    setTimeout(() => {
      setSearch("");
    }, 200);
  }, []);

  const handleProductClick = useCallback(
    (slug) => {
      ajaxService.get(`/product/search-log/${slug}`);
      navigate(`/product/${slug}`);
    },
    [navigate]
  );

  const loadProducts = useCallback(async () => {
    const { success, data } = await ajaxService.get(
      `/category/products?category_id=0&type=${CATEGORIES.ALL}`
    );
    if (success) {
      setProducts(data);
    }
  }, []);

  useEffect(() => {
    loadProducts();
  }, [loadProducts]);

  const DrawerList = (
    <Box
      sx={{
        width: 250,
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}
      className="px-1"
      role="presentation"
      onClick={() => setOpen(false)}
    >
      <Link
        to="/"
        className="flex justify-center items-center "
      >
        <img
          src={logo}
          className="h-[54px] w-auto my-4 -ms-4"
          alt="burraq star logo"
        />
      </Link>
      <Divider className="my-4" />

      <React.Fragment >
        <Link to={isLoggedIn ? AppRoute.dashboard : AppRoute.login} className="flex items-center my-1 ms-4">
          <span className="text-lg text-[#2858A3] -mt-1 pe-4">
            {isLoggedIn ? <DashboardOutlinedIcon /> : <LoginOutlinedIcon />}
          </span>
          <Typography className="poppins text-base text-black">
            {isLoggedIn ? "Dashboard" : "Login"}
          </Typography>
        </Link>
        <Divider className="my-4" />
      </React.Fragment>

      
      <React.Fragment >
        <Link to="/category" className="flex items-center my-1 ms-4">
          <span className="text-lg text-[#2858A3] -mt-1 pe-4">
          <CategoryOutlinedIcon />
          </span>
          <Typography className="poppins text-base text-black">
          Categories
          </Typography>
        </Link>
        <Divider className="my-4" />
      </React.Fragment>


      {routes.map((route, i) => (
        <React.Fragment key={i} >
          <Link to={route.link} className="flex items-center my-1 ms-4">
            {route.mobIcon && (
              <span className="text-lg text-[#2858A3] -mt-1 pe-4">
                {route.mobIcon}
              </span>
            )}
            <Typography className="poppins text-base text-black">
              {route.title}
            </Typography>
          </Link>
          <Divider className="my-4" />
        </React.Fragment>
      ))}

      {/* <MobileCategories /> */}

    </Box>

  );

  {/* {routes.map((route, i) => (
        <React.Fragment key={i}>
          <Link to={route.link} className="flex items-center">
            {route.mobIcon && (
              <span className="text-lg text-[#2858A3] -mt-1 pe-4">
                {route.mobIcon}
              </span>
            )}
            <Typography className="poppins text-base text-black">
              {route.title}
            </Typography>
          </Link>
          <Divider className="my-4" />
        </React.Fragment>
      ))} */}

  return (
    <Box className="h-20 sm:h-28 w-full sticky z-10 top-0 bg-white gap-x-20 flex items-center justify-center px-2.5 sm:px-9 sm:py-5">
      <Box className="flex items-center gap-x-3">
        <IconButton
          className="p-0 block sm:block lg:hidden"
          onClick={() => setOpen(true)}
        >
          <MenuIcon className="text-3xl text-[#2858A3]" />
        </IconButton>
        <Drawer open={open} onClose={() => setOpen(false)}>
          {DrawerList}
        </Drawer>
        <Link to="/">
          <img
            src={logo}
            className="h-[54px] md:h-[62px] lg:h-[80px] w-auto"
            alt="burraq star logo"
          />
        </Link>
      </Box>

      <Box className="flex relative items-center h-full gap-x-2 sm:gap-x-5 sm:w-[65%]">
        {isSmUp ? (
          <Box className="w-[62%] h-[85%] relative bg-[#f0f0f0] rounded-lg flex items-center justify-between px-8 py-4">
            <input
              className="w-[80%] bg-transparent outline-none text-[#2858A3]"
              placeholder="Search Here..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onBlur={handleInputBlur}
            />
            <SearchIcon className="text-2xl text-[#2858A3]" />
            {search.length > 0 && (
              <Box className="absolute top-16 border-2 left-0 rounded-lg z-50 w-full h-[50vh] overflow-y-auto bg-white">
                {products
                  .filter(
                    (prod) =>
                      prod.name.toLowerCase().includes(search.toLowerCase()) ||
                      prod.item_code
                        .toLowerCase()
                        .includes(search.toLowerCase())
                  )
                  .map((product, i) => (
                    <ListItem
                      key={i}
                      disablePadding
                      onClick={() => handleProductClick(product.slug)}
                    >
                      <ListItemButton className="rounded">
                        <Box className="flex w-full h-fit">
                          <img
                            src={ImageURL + product.images[0]}
                            alt={product.name}
                            className="h-10 w-10"
                          />
                          <Typography className="text-xs ps-3 text-black poppins">
                            {product.name}
                          </Typography>
                        </Box>
                      </ListItemButton>
                    </ListItem>
                  ))}
              </Box>
            )}
          </Box>
        ) : (
          <SearchIcon
            className="cursor-pointer text-2xl text-[#2858A3]"
            onClick={() => setSearchOpen(!searchOpen)}
          />
        )}

        <Link to="/cart">
          <IconButton>
            <StyledBadge badgeContent={totalUniqueItems} color="primary">
              <ShoppingCartIcon className="text-[#2858A3] text-2xl sm:text-4xl" />
            </StyledBadge>
          </IconButton>
        </Link>

        <Box className="h-full flex items-center ml-2">
          <Link
            to={isLoggedIn ? "/user/dashboard" : "/user/login"}
            className="h-full flex items-center"
          >
            <Typography className="poppins font-semibold hidden sm:hidden lg:block">
              {isLoggedIn ? "Dashboard" : "Login"}
            </Typography>
          </Link>
          <Typography className="poppins hidden sm:hidden lg:block mx-3">
            Or
          </Typography>
          {isLoggedIn ? (
            <Typography
              className="poppins font-semibold h-full flex items-center cursor-pointer"
              onClick={handleLogout}
            >
              Logout
            </Typography>
          ) : (
            <Link to="/user/registration" className="h-full flex items-center">
              <Typography className="poppins font-semibold hidden sm:hidden lg:block">
                Registration
              </Typography>
            </Link>
          )}
        </Box>
      </Box>

      {searchOpen && !isSmUp && (
        <Box className="h-16 w-full z-50 absolute -bottom-[65px] left-0 flex items-center p-2.5 bg-[#f0f0f0] sm:hidden">
          <Box className="w-full h-full bg-white rounded-lg flex items-center justify-between px-8 py-4">
            <input
              className="w-[80%] bg-transparent outline-none text-[#2858A3]"
              placeholder="Search Here..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onBlur={handleInputBlur}
            />
            <SearchIcon className="text-2xl text-[#2858A3]" />
          </Box>
          {search.length > 0 && (
            <Box className="absolute top-[68px] border-2 left-0 rounded-lg z-50 w-full h-[50vh] overflow-y-auto bg-white">
              {products
                .filter((prod) =>
                  prod.name.toLowerCase().includes(search.toLowerCase())
                )
                .map((product, i) => (
                  <ListItem
                    key={i}
                    disablePadding
                    onClick={() => handleProductClick(product.slug)}
                  >
                    <ListItemButton className="rounded">
                      <Box className="flex w-full h-fit">
                        <img
                          src={ImageURL + product.images[0]}
                          alt={product.name}
                          className="h-10 w-10"
                        />
                        <Typography className="text-xs ps-3 text-black poppins">
                          {product.name}
                        </Typography>
                      </Box>
                    </ListItemButton>
                  </ListItem>
                ))}
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default React.memo(HeaderBar);
