import { Button } from "@mui/material"
import { useState, useContext, useEffect } from "react";
import { useCart } from "react-use-cart";
import NotificationBar from "./NotificationBar";
import { CircularProgress } from "@mui/material";
import { BASE_URL } from "../constants";
import axios from "axios";
import { AuthContext } from "../AuthContext";

const AddToCart = ({ variant = "contained", className, quantity = 1, product }) => {
    const { setCartId } = useContext(AuthContext)
    const { addItem } = useCart();

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(false);
    const [outOfStock, setOutOfStock] = useState(false);

    const handleClick = (e, product) => {
        // for stock navigating in hote sale section when click on add to cart
        e.stopPropagation();
        // setOpen(true);
        // if (product.stocks === 0) {
        //     setOutOfStock(true);
        // } 

        const data = {
            id: product.id,
            name: product.name,
            stocks: product.stocks,
            price: product.discount_price ?? product.price,
            quantity: quantity,
            img:product.images[0]
        }

        setLoading(true);
        if(product.stocks !== 0) {
            addItem(data, quantity);
            setMessage('Product added to cart');
            setOpen(true);
            setLoading(false);

            // axios.post(`${BASE_URL}/api/v1/carts/add`, {
            //     product_id: product?.id,
            //     qty: quantity
            // }, {
            //     headers: {
            //         Authorization: `Bearer ${localStorage?.getItem('token')}`
            //     },
            // })
            // .then((response) => {
            //   setLoading(false)
            //   if (!response?.data?.success) {
            //     setOpen(true);
            //   }else if(response?.data?.success) {
            //     setCartId(response?.data?.data?.cart_id)
            //     localStorage.setItem('cart_id', response?.data?.data?.cart_id)
            //     setMessage(response?.data?.message);
            //     setOpen(true);
            //   }
            // })
            // .catch((error) => {
            //   console.error("Error:", error);
            //   setLoading(false);
            // });

        } else if(product.stocks === 0) {
            setOutOfStock(true);
            setLoading(false);
            setMessage('Out of stock. Please check back later.')
            setOpen(true)
            return;
        }
    }
   

    return (
        <>
            <Button variant={variant} className={className} onClick={(e) => handleClick(e, product)}>
                {loading && <CircularProgress size={25} className="text-white" />}
                {!loading && "Add to cart"}
            </Button>

            {/* <NotificationBar
                open={open}
                setOpen={setOpen}
                type={outOfStock ? "error" : "success"}
                message={outOfStock ? "Out of stock. Please check back later." : message}
            /> */}

            <NotificationBar
                open={open}
                setOpen={setOpen}
                type={outOfStock ? "error" : "success"}
                message={message}
            />
        </>
    )
}

export default AddToCart