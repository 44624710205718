import React, { createContext, useState, useEffect } from 'react';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('token'));
    const [user, setUser] = useState(() => {
        const userString = localStorage.getItem('user');
        return userString ? JSON.parse(userString) : null;
    });

    const login = (userData, token, expires_at) => {
        localStorage.setItem('user', JSON.stringify(userData));
        localStorage.setItem('token', token);
        localStorage.setItem('expires_at', expires_at);
        setIsLoggedIn(true);
        setUser(userData);
    };

    const logout = () => {
        setIsLoggedIn(false);
        setUser(null);
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        localStorage.removeItem('expires_at');
    };

    useEffect(() => {
        const checkTokenExpiry = () => {
            const expiresAt = localStorage.getItem('expires_at');
            if (expiresAt && new Date().getTime() > new Date(expiresAt).getTime()) {
                logout();
            }
        };

        // Check token expiry on initial load and periodically
        checkTokenExpiry();
        const interval = setInterval(checkTokenExpiry, 60000); // Check every minute

        return () => clearInterval(interval);
    }, []);

    return (
        <AuthContext.Provider value={{ isLoggedIn, user, login, logout, setUser }}>
            {children}
        </AuthContext.Provider>
    );
};

export { AuthContext, AuthProvider };
