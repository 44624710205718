
import React from "react";
import { Badge, Box, Button, Divider, IconButton, styled } from "@mui/material";
import { Link } from "react-router-dom";
import { ImageURL } from "../../constants";
import { useCart } from "react-use-cart";
import { Add, Remove } from "@mui/icons-material";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
// import { Add, Remove } from "@mui/icons-material";
// import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
// import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

const CartReview = () => {

    const { items, totalUniqueItems, removeItem, updateItemQuantity } = useCart();

    const ImageWithBadges = styled(Box)({
        position: 'relative',
        display: 'inline-block',
      });
      
      const IconLeft = styled(Box)({
        position: 'absolute',
        top: '0',
        left: '0',
        transform: 'translate(-50%, -50%)',
        cursor: 'pointer',
      });
      
      const BadgeRight = styled(Badge)({
        position: 'absolute',
        top: '0',
        right: '0',
        transform: 'translate(50%, -50%)',
      });
      
      const ImageComponent = ({ row }) => (
        <ImageWithBadges>
          <IconLeft onClick={() => removeItem(row?.id)}>
            <DeleteOutlineIcon style={{ fontSize: '1.25rem', color: "#909090" }} />
          </IconLeft>
          <img src={`${ImageURL}${row?.img}`} height="70px" width="70px" alt="product" />
          <BadgeRight badgeContent={row.quantity} color="primary">
            <Box component="span" />
          </BadgeRight>
        </ImageWithBadges>
      );

    const Header = (
        <>
            <Box className="p-5" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box style={{ display: 'flex' }}>
                    <p style={{ fontWeight: 900, fontSize: 'px' }}>Review Your Order({totalUniqueItems})</p>
                </Box>
            </Box>
        </>
    )

    const Footer = (
        <Box className="p-5" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button component={Link} to="/cart" variant='contained' className="w-[100%]" style={{ textTransform: "capitalize", background: '#2858A3', fontWeight: 800 }}>Back To Cart</Button>
        </Box>
    )

    const content = (
        <>
            {
                items.map((row, index) => (
                    <>
                        <Box key={`box-${index}`} style={{ padding: '15px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <IconButton size="small" className="bg-[#2858A3]" onClick={() => updateItemQuantity(row.id, row.quantity - 1)}>
                                <Remove className="text-sm text-white" />
                            </IconButton>
                            <ImageComponent row={row} />
                            <IconButton size="small" className="bg-[#2858A3]" onClick={() => updateItemQuantity(row.id, (row.quantity + 1) > row.stocks ? row.stocks : (row.quantity + 1))}>
                                <Add className="text-sm text-white" />
                            </IconButton>
                            <Box>
                                <p className="text-[12px]" style={{ textOverflow: "ellipsis", overflow: "hidden", width: "150px", whiteSpace: "nowrap" }}>{row?.name}</p>
                            </Box>
                            <Box className="text-[14px]">
                                <p className="text-[#2858A3]">{row.itemTotal.toFixed(2)} {row?.product?.currency ? row?.product?.currency : 'AED'}</p>
                            </Box>
                        </Box>
                        <Divider />
                    </>
                ))
            }
        </>
    )

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                background: "#fff",
                borderRadius: '4px'
            }}
            role="presentation"
        >
            {Header}
            <Box sx={{ flex: '1 1 auto', overflow: 'auto', paddingX: 0 }}>
                {content}
            </Box>
            {Footer}
        </Box>
    )
}

export default CartReview