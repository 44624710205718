import React from "react";
import { Box } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Navigation } from "swiper/modules";
import blogImage from "../../assets/blog.jpg";
import BlogCard from "./BlogCard";

const SliderTwo = ({ blogs }) => {
  return (
    <Box className="bg-transparent h-fit w-full px-5 md:px-10 lg:px-20 mb-14">
      <Swiper
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        breakpoints={{
          0: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          480: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
        }}
        modules={[Autoplay, Navigation]}
        className="h-full w-full popularSlider relative -mt-14"
      >
        <div className="absolute bottom-10 left-[50%] hidden sm:block">
          <div className="swiper-button-prev -left-14 bg-[#02adec] w-10 h-10 flex justify-center items-center rounded-full">
            <ChevronLeftIcon className="text-sm text-white" />
          </div>
          <div className="swiper-button-next -right-14 bg-[#02adec] w-10 h-10 flex justify-center items-center rounded-full">
            <ChevronRightIcon className="text-sm text-white" />
          </div>
        </div>
        {blogs.map((blog, i) => (
          <SwiperSlide key={i} className="mb-20 pb-2">
            <BlogCard blog={blog} />
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};

export default SliderTwo;
