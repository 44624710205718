import React, { useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import bg from "../assets/contactbg.jpg";
import watermark from "../assets/contactsvg.svg";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import { Hero, BrandBanner } from "../components";

const Contact = () => {

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");

  const validate = () => {
    let tempErrors = {};
    if (!formData.name) tempErrors.name = "Please enter your name.";
    if (!formData.email) {
      tempErrors.email = "Please enter your email.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      tempErrors.email = "Please enter a valid email address.";
    }
    if (!formData.phone) tempErrors.phone = "Please enter your phone number.";
    if (!formData.message) tempErrors.message = "Please enter your message.";
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleRequestQuote = async () => {
    if (validate()) {
      try {
        // const response = await axios.post(`${BASE_URL}/api/`, {
        //   name: formData.name,
        //   email: formData.email,
        //   phone: formData.phone,
        //   message: formData.message,
        // });

        // if (response.data.success) {
        if (true) {
          setSuccessMessage("Your quote request has been submitted successfully!");
          setFormData({ name: "", email: "", phone: "", message: "" });
          setErrors({});
        } else {
          setErrors({ form: response.data.message });
        }
      } catch (error) {
        setErrors({ form: "An error occurred while submitting your request. Please try again later." });
      }
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  return (
    <Box className="w-full h-auto bg-white m-0 p-0">
      <Hero bg={bg} title={"Contact Us"} />
      <Grid
        className="w-full h-fit py-24 px-5 md:py-4 md:px-14 pb-10 sm:pb-10 lg:px-32 bg-no-repeat bg-center"
        container
        sx={{
          backgroundImage: `url(${watermark})`,
          backgroundSize: {
            md: "40%",
            sm: "55%",
            xs: "0",
          },
        }}
      >
        <Grid
          item
          xs={12}
          sm={6}
          className="bg-no-repeat bg-center"
          sx={{
            backgroundImage: `url(${watermark})`,
            backgroundSize: {
              md: "0",
              sm: "0",
              xs: "90%",
            },
          }}
        >
          <Typography className="poppins text-5xl text-black">
            Contact Us
          </Typography>
          <Box className="py-7 me-0 md:me-[20%] lg:me-[30%] border-b-2 border-dashed">
            <Typography className="poppins text-base sm:text-lg uppercase font-semibold text-[#02adec]">
              Sharjah Office
            </Typography>
            <Typography className="poppins text-base capitalize font-medium text-[#25252A] w-full">
              1307 - 13th floor al mulla towerittihad road near ansar mall
            </Typography>
            <Typography className="poppins text-base sm:text-lg font-medium capitalize text-[#25252A] w-full">
              tel: +971 6 5616976
            </Typography>
          </Box>
          <Box className="py-7 me-0 md:me-[20%] lg:me-[30%] border-b-2 border-dashed">
            <Typography className="poppins text-base sm:text-lg uppercase font-semibold text-[#02adec]">
              Showroom
            </Typography>
            <Typography className="poppins text-base capitalize font-medium text-[#25252A] w-full">
              5,6 & 7 Nakhel Road,Deira
            </Typography>
            <Typography className="poppins text-base sm:text-lg font-medium capitalize text-[#25252A] w-full">
              Tel: +971 4 2248520
            </Typography>
          </Box>
          <Box className="py-7 me-0 md:me-[20%] lg:me-[30%] border-b-2 border-dashed">
            <Typography className="poppins text-base sm:text-lg uppercase font-semibold text-[#02adec]">
              WAREHOUSE
            </Typography>
            <Typography className="poppins text-base capitalize font-medium text-[#25252A] w-full">
              F-7 Industrial Area No. 8 Sharjah
            </Typography>
            <Typography className="poppins text-base sm:text-lg font-medium capitalize text-[#25252A] w-full">
              Tel: +971 6 5313787
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          className="w-full h-fit py-10 px-5 md:py-10 md:px-5 lg:p-10 mt-8 sm:mt-0 bg-[#F4F6F7] rounded-md flex flex-col items-center"
        >
          <Typography className="poppins text-3xl md:px-3 px-0 lg:px-0 text-black">
            Request a Quote Today
          </Typography>

          <Box className="flex flex-col items-start w-full px-0 sm:px-5 pt-8">
            <Typography className="poppins font-semibold uppercase text-xs text-black">
              Your Name
            </Typography>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="text-black bg-white rounded-md w-full text-xs outline-none poppins p-4"
              placeholder="Enter your name"
            />
            {errors.name && (
              <Typography className="poppins text-xs text-red-500">
                {errors.name}
              </Typography>
            )}
          </Box>

          <Box className="flex flex-col items-start w-full px-0 sm:px-5 pt-3">
            <Typography className="poppins font-semibold uppercase text-xs text-black">
              Email Address
            </Typography>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="text-black bg-white rounded-md w-full text-xs outline-none poppins p-4"
              placeholder="Enter your email"
            />
            {errors.email && (
              <Typography className="poppins text-xs text-red-500">
                {errors.email}
              </Typography>
            )}
          </Box>

          <Box className="flex flex-col items-start w-full px-0 sm:px-5 pt-3">
            <Typography className="poppins font-semibold uppercase text-xs text-black">
              Phone
            </Typography>
            <input
              type="text"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              className="text-black bg-white rounded-md w-full text-xs outline-none poppins p-4"
              placeholder="Enter your phone"
            />
            {errors.phone && (
              <Typography className="poppins text-xs text-red-500">
                {errors.phone}
              </Typography>
            )}
          </Box>

          <Box className="flex flex-col items-start w-full px-0 sm:px-5 pt-3">
            <Typography className="poppins font-semibold uppercase text-xs text-black">
              Message
            </Typography>
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              className="text-black bg-white rounded-md w-full text-xs outline-none poppins p-4"
              placeholder="Enter your message"
              rows="4"
            />
            {errors.message && (
              <Typography className="poppins text-xs text-red-500">
                {errors.message}
              </Typography>
            )}
          </Box>

          {errors.form && (
            <Typography className="poppins text-xs text-red-500 mt-3">
              {errors.form}
            </Typography>
          )}

          {successMessage && (
            <Typography className="poppins text-xs text-green-500 mt-3">
              {successMessage}
            </Typography>
          )}

          <Box className="flex flex-col items-start w-full px-0 sm:px-5 pt-3">
            <Button
              onClick={handleRequestQuote}
              className="bg-[#02ADEC] text-white rounded-sm text-xs poppins py-2.5 px-8"
            >
              Submit
            </Button>
          </Box>
        </Grid>
      </Grid>

      <Grid container className="w-full h-fit px-5 md:px-10 lg:px-32 mb-16">
        <Grid item xs={12} sm={6} className="mt-12">
          <Box className="w-full h-fit flex">
            <Box className="flex justify-center items-center h-14 sm:h-20 w-14 sm:w-20 rounded-lg bg-[#02ADEC]">
              <LocalPhoneOutlinedIcon className="text-white text-2xl sm:text-4xl" />
            </Box>
            <Box className="ps-3 h-14 sm:h-20 flex flex-col justify-center">
              <Typography className="poppins text-base text-[#02ADEC] font-semibold">
                Phone
              </Typography>
              <Typography className="poppins text-base text-black">
                800-NOVEX (66839)
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} className="mt-12">
          <Box className="w-full h-fit flex">
            <Box className="flex justify-center items-center h-14 sm:h-20 w-14 sm:w-20 rounded-lg bg-[#02ADEC]">
              <ChatBubbleOutlineOutlinedIcon className="text-white text-2xl sm:text-4xl" />
            </Box>
            <Box className="ps-3 h-14 sm:h-20 flex flex-col justify-center">
              <Typography className="poppins text-base text-[#02ADEC] font-semibold">
                Email
              </Typography>
              <Typography className="poppins text-sm sm:text-base text-black">
                ecommerce@buraqstar.com
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <BrandBanner />
    </Box>
  );
};

export default Contact;
