import React, { useContext } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { routes } from './utils';

// Pages and Components
import Home from "./pages/Home";
import Products from "./pages/Products";
import Product from "./pages/Product";
import About from "./pages/About";
import Terms from "./pages/Terms";
import Policy from "./pages/Policy";
import Blogs from "./pages/Blogs";
import Blog from "./pages/Blog";
import Contact from "./pages/Contact";
import FAQ from "./pages/FAQ";
import Login from "./pages/user/auth/Login";
import Registration from "./pages/user/auth/Registration";
import Verify from "./pages/user/auth/Verify";
import Wishlist from "./pages/user/wishlist";
import Checkout from "./pages/Checkout";
import CartPage from "./components/Cart/CartPage";
import DashboardComponent from "./pages/user/panel/Dashboard";
import MyOrder from "./pages/user/panel/MyOrder";
import ThankYouPage from "./pages/Thankyou";
import StoreLocator from "./pages/StoreLocator";
import { AuthContext } from "./AuthContext";
import UserLayout from "./components/Layout/UserLayout";
import DashboardLayout from "./components/Layout/DashboardLayout";
import FadeTransition from "./components/FadeTransition";
import MyProfile from "./pages/user/panel/MyProfile";



const AppRoutes = () => {
  const location = useLocation();
  const { isLoggedIn } = useContext(AuthContext);

  const dashboardRoute = (path, Component, title) => (
    <Route
      path={path}
      element={
        isLoggedIn ? (
          <DashboardLayout title={title}>
            <Component />
          </DashboardLayout>
        ) : (
          <Navigate to={routes.login} />
        )
      }
    />
  );
  

  return (
    <FadeTransition>
      <Routes location={location}>
        {/* User-facing routes with UserLayout */}
        <Route path={routes.home} element={<UserLayout><Home /></UserLayout>} />
        <Route path={routes.thankYou} element={<UserLayout><ThankYouPage /></UserLayout>} />
        <Route path={routes.category} element={<UserLayout><Products /></UserLayout>} />
        <Route path={routes.checkout} element={<UserLayout><Checkout /></UserLayout>} />
        <Route path={routes.brand} element={<UserLayout><Products /></UserLayout>} />
        <Route path={routes.deals} element={<UserLayout><Products /></UserLayout>} />
        <Route path={routes.product} element={<UserLayout><Product /></UserLayout>} />
        <Route path={routes.about} element={<UserLayout><About /></UserLayout>} />
        <Route path={routes.terms} element={<UserLayout><Terms /></UserLayout>} />
        <Route path={routes.policy} element={<UserLayout><Policy /></UserLayout>} />
        <Route path={routes.blogs} element={<UserLayout><Blogs /></UserLayout>} />
        <Route path={routes.blog} element={<UserLayout><Blog /></UserLayout>} />
        <Route path={routes.contact} element={<UserLayout><Contact /></UserLayout>} />
        <Route path={routes.faq} element={<UserLayout><FAQ /></UserLayout>} />
        <Route path={routes.cart} element={<UserLayout><CartPage /></UserLayout>} />
        <Route path={routes.storeLocator} element={<UserLayout><StoreLocator /></UserLayout>} />

        {/* Authentication Routes */}
        <Route path={routes.login} element={<UserLayout><Login /></UserLayout>} />
        <Route path={routes.registration} element={<UserLayout><Registration /></UserLayout>} />
        <Route path={routes.verify} element={<UserLayout><Verify /></UserLayout>} />

        {/* Dashboard Routes */}
        {dashboardRoute(routes.dashboard, DashboardComponent, "Dashboard")}
        {dashboardRoute(routes.wishlist, Wishlist, "Wishlist")}
        {dashboardRoute(routes.myOrder, MyOrder, "My Orders")}
        {dashboardRoute(routes.myProfile, MyProfile, "My Profile")}
      </Routes>
    </FadeTransition>
  );
};

export default AppRoutes;
