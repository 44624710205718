import { useEffect, useState } from "react";
import ajaxService from "../../../services/ajax-service";
import Dropdown from 'react-multilevel-dropdown';
import { Link } from "react-router-dom";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

const Categories = () => {

    const [categories, setCategories] = useState([])
    const [categoryOpen, setCategoryOpen] = useState(false);

    const loadCategories = async () => {
        const { success, data } = await ajaxService.get('/all-categories');
        if (success) {
            setCategories(data);
        }
    }

    useEffect(() => {
        loadCategories();
    }, [])

    const childCategories = (parentId, childrens) => (
        <>
            {childrens.length > 0
                &&
                <>
                    <Dropdown.Submenu position="right" className={'border-b-[3px] border-[#2858a3] rounded-b-md bg-[#f1f1f1]'} style={{ width: '107%' }}>
                        {
                            childrens.map((child, index) => (
                                <Dropdown.Item key={`child-${index}`} className="className={`flex items-center justify-between gap-x-14`}">
                                    <Link
                                        to={`/category?parent_id=${parentId}&child_id=${child.id}`}
                                        className={`w-full flex items-center justify-between gap-x-14`}
                                    >
                                        {child.name}
                                    </Link>
                                    {child.childrens && child.childrens.length > 0 && subChildCategories(parentId, child.id, child.childrens)}
                                </Dropdown.Item>
                            ))
                        }
                    </Dropdown.Submenu>
                </>
            }
        </>
    )

    const subChildCategories = (parentId, childId, subChildrens) => (
        <>
            {subChildrens.length > 0
                &&
                <>
                    <ArrowRightIcon className="text-sm text-[#2858a3]" />

                    <Dropdown.Submenu position="right" className={'border-b-[3px] border-[#2858a3] rounded-b-md bg-[#f1f1f1]'} style={{ width: '107%' }}>
                        {
                            subChildrens.map((child, index) => (
                                <Dropdown.Item key={`child-${index}`} className="className={`flex items-center justify-between gap-x-14`}">
                                    <Link
                                        className="w-full flex items-center justify-between gap-x-14"
                                        to={`/category?parent_id=${parentId}&child_id=${childId}&sub_child_id=${child.id}`}
                                    >
                                        {child.name}
                                    </Link>
                                </Dropdown.Item>
                            ))
                        }
                    </Dropdown.Submenu>
                </>
            }
        </>
    )

    return (
        <Dropdown
            position="right"
            title={<>Category {categoryOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}</>}
            className="poppins text-sm text-white flex items-center relative"
            onClick={() => setCategoryOpen(!categoryOpen)}
            menuClassName="border-b-[3px] border-[#2858a3] rounded-b-md bg-[#f1f1f1] text-black h-fit absolute z-50 top-9 left-0 block"
        >

            <Dropdown.Item className={`w-full flex items-center justify-between gap-x-14`}>
                <Link
                    to={`/category?type=all`}
                    className={`w-full flex items-center justify-between gap-x-14`}
                >
                    View All Items
                </Link>
            </Dropdown.Item>

            {categories.map((cat, i) => (
                <Dropdown.Item key={i} className={`w-full flex items-center justify-between gap-x-14`}>
                    <Link
                        to={`/category?parent_id=${cat.id}`}
                        className={`w-full flex items-center justify-between gap-x-14`}
                    >
                        {cat.name}
                        <ArrowRightIcon className="text-sm text-[#2858a3]" />
                    </Link>
                    {childCategories(cat.id, cat.childrens)}
                </Dropdown.Item>
            ))}
        </Dropdown>
    )
}

export default Categories