import React from 'react';
import { useLocation } from 'react-router-dom';
import Footer from './Footer';
import ScrollToTop from '../ScrollToTop';
import FloatingCart from '../Cart/FloatingCart';
import Header from './Header';

const UserLayout = ({ children }) => {
  const location = useLocation();
  // Array of paths where FloatingCart should not be displayed
  const noFloatingCartPaths = ["/cart", "/checkout"];

  // Determine if FloatingCart should be displayed
  const showFloatingCart = !noFloatingCartPaths.includes(location.pathname);

  return (
    <div className="min-h-screen flex flex-col">
      {/* Header */}
      <Header />
      <ScrollToTop />

      {/* Main Content */}
      <div className="xlg:flex-grow xlg:container xlg:mx-auto xlg:px-4">
        {children}
      </div>

      {/* Footer */}
      <Footer />
      {showFloatingCart && <FloatingCart />}
    </div>
  );
};

export default UserLayout;
