import React, { useState } from "react";
import { Box, Fab, Drawer, Badge } from "@mui/material";
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import DrawerContent from "./DrawerContent";
import { useCart } from "react-use-cart";
import { styled } from '@mui/material/styles';

const StyledBadge = styled(Badge)(({ theme }) => ({
    '.MuiBadge-badge': {
        position: 'absolute',
        top: '6px',    // Adjust these values to position the badge correctly
        right: '12px',   // Adjust these values to position the badge correctly
        backgroundColor: '#1e55ac',  // Amazon's orange color
        color: 'white',
        fontWeight: 'bold',
        color: 'white',
        fontWeight: 'bold',
        fontSize: '12px',  // Smaller font size for badge content
        minWidth: '10px',  // Adjust the minimum width to make it smaller
        height: '10px',    // Adjust the height to make it smaller
        padding: '0',      // Remove any extra padding
        borderRadius: '50%',  // Keep the circular shape
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

const FloatingCart = () => {

    const { totalUniqueItems, cartTotal } = useCart();

    const [open, setOpen] = useState(false);

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setOpen(open);
    };

    // useEffect(()=> {
    //     axios.post(`${BASE_URL}/api/v1/carts`, {
    //         cart_id: CartId,
    //         type: ''
    //     },{
    //         headers: {
    //             Authorization: `Bearer ${localStorage?.getItem('token')}`
    //         },
    //     })
    //     .then((res) => {
    //         const products = res?.data?.cart_items.map((item) => {
    //             return {
    //                 cart_id: item?.id,
    //                 product: item?.product,
    //                 quantity: item?.quantity
    //             };
    //         });
    //         setItems(products);
    //         setTotalItems(products?.length)
    //         CalcTotal(products)
    //     })
    //     .catch((error) => {
    //       console.error("Error:", error);
    //     });
    // }, [CartId])

    const CalcTotal = (items) => {
        const total = items?.reduce((acc, item) => {
            const quantity = Number(item.quantity);
            const price = item?.product?.price || 0;
            return acc + (quantity * price);
        }, 0);
        setCartTotal(total)
    }



    const FloatingButton = (
        <Fab
            variant="extended"
            color="primary"
            onClick={() => setOpen(true)}
            sx={{
                padding: { xs: '0px 0px', sm: '8px' },
                position: 'fixed',
                bottom: { xs: cartTotal > 0 ? '75%' : '80%', sm: '50%' },  // Adjust for mobile
                right: 0,
                background: '#2858a3',
                borderRadius: '10px 0px 0px 10px',
                height: { xs: cartTotal > 0 ? '70px' : '40px', sm: '90px' }, // Adjust for mobile
                width: { xs: cartTotal > 0 ? '70px' : '40px', sm: '120px' }, // Adjust for mobile
                color: '#fff',
                zIndex: 1000, // Ensure it appears above other content
                '&:hover': {
                    background: '#1e4480', // Darken on hover
                },
            }}
        >
            <Box className="hidden sm:block">
                <p style={{ fontSize: '.75rem' }}><ShoppingCartCheckoutIcon /> <span>{totalUniqueItems} Item(s)</span></p>
                <Box className='bg-[#fff] text-[#2858a3] p-1.5 mt-1.5' style={{ fontSize: '12px', borderRadius: '4px' }}> AED {cartTotal}</Box>
            </Box>

            <Box className="sm:hidden">
                {/* <Badge badgeContent={totalUniqueItems > 0 ? totalUniqueItems : 0} color="white">
                    <ShoppingCartCheckoutIcon color="white" />
                </Badge> */}

                <div className="relative inline-block">
                    <StyledBadge
                        badgeContent={totalUniqueItems > 0 ? totalUniqueItems : '0'}
                        overlap="rectangular" // Use "rectangular" to align more closely with the image
                    >
                        <ShoppingCartCheckoutIcon />
                    </StyledBadge>
                </div>

                {
                    cartTotal > 0 && (
                        <>
                            <br />
                            <Badge className="text-[12px] mt-2" badgeContent={
                                <span className="text-[8px] sm:text-xs me-1">
                                    AED
                                </span>
                            } color="white">
                                {cartTotal > 0 ? cartTotal.toFixed(2) : cartTotal}
                            </Badge>
                        </>
                    )
                }

            </Box>

        </Fab>
    )

    const CartDrawer = (
        <>
            {/* <Drawer
                anchor={'right'}
                className="sm:hidden"
                open={open}
                onClose={toggleDrawer(false)}
            >
                <Box
                    sx={{
                        width: '100vw',
                        height: '100vh',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                    className="px-1"
                    role="presentation"
                >
                    <DrawerContent
                        setOpen={setOpen}
                    />
                </Box>
            </Drawer> */}

            <Drawer
                anchor={'right'}
                open={open}
                onClose={toggleDrawer(false)}
            >
                <DrawerContent
                    setOpen={setOpen} />
            </Drawer>
        </>
    )

    return (
        <>
            {/* {isLoggedIn && */}
            <>
                {FloatingButton}
                {CartDrawer}
            </>
            {/* } */}
        </>
    )
}

export default FloatingCart