import React from "react";
import { Box, Divider } from "@mui/material";
import bg from "../assets/about_hero.jpg";
import { Banner, Banner2, Brands, Certifications, Gallery, Hero, Message, Wrapper } from "../components";

const About = () => {
  return (
    <Box className="w-full h-auto bg-white m-0 p-0">
      <Hero bg={bg} title={"About Us"} />
      <Message />
      <Banner />
      <Brands />
      <Box className="flex justify-center">
        <Divider className="bg-[#CCCCCC] w-[90%] mb-10" />
      </Box>
      <Certifications />
      <Banner2 />
      <Gallery />
      <Box className="flex justify-center bg-[#F4F6F7] pt-6">
        <Divider className="bg-[#CCCCCC] w-[90%] mb-10" />
      </Box>
      <Wrapper />
    </Box>
  );
};

export default About;
