import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper/modules";
import { Box, Button, Typography } from "@mui/material";
import defaultImage from "../../assets/dummy.png";
import electricalImage from "../../assets/category/electrical.jpg";
import hardwareImage from "../../assets/category/hardware.webp";
import sanitaryImage from "../../assets/category/sanitary.webp";
import allItems from "../../assets/all_items.jpg";
import { Link, useNavigate } from "react-router-dom";

const categoryBackgrounds = [
  { name: 'Electrical', src: electricalImage },
  { name: 'Hardware', src: hardwareImage },
  { name: 'Sanitary', src: sanitaryImage },
];

const CategorySlider = ({ categories }) => {

  const navigate = useNavigate();

  return (
    <Box className="h-screen w-full pb-0 p-10 md:py-20 md:px-14 md:pb-0 lg:pb-0 lg:p-24">
      <Swiper
        className="h-full categorySlider"
        modules={[Pagination, Autoplay]}
        pagination={{ clickable: true }}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        breakpoints={{
          0: { slidesPerView: 1, spaceBetween: 20 },
          480: { slidesPerView: 1, spaceBetween: 20 },
          768: { slidesPerView: 2, spaceBetween: 20 },
          1024: { slidesPerView: 3, spaceBetween: 20 },
        }}
        style={{
          "--swiper-pagination-color": "#2858a3",
          "--swiper-pagination-bullet-inactive-color": "transparent",
          "--swiper-pagination-bullet-inactive-opacity": "1",
          "--swiper-pagination-bullet-size": "16px",
          "--swiper-pagination-bullet-horizontal-gap": "6px",
        }}
      >
        {categories.map((val, i) => {
          // Find the background image based on category name
          const categoryBg = categoryBackgrounds.find(cat => cat.name.toLowerCase() === val.name.toLowerCase());

          // Determine the final background image to use
          const backgroundImage = val.id > 0 
          ? (val.banner && val.banner !== '' 
              ? val.banner 
              : (categoryBg && categoryBg.src) 
                ? categoryBg.src 
                : defaultImage) 
          : allItems;
      

          return (
            <SwiperSlide
              key={i}
              className="categories-slide rounded-2xl flex flex-col h-[76%] items-center justify-end p-8 bg-cover bg-no-repeat bg-center"
              style={{ backgroundImage: `url(${backgroundImage})` }}
              // style={{ backgroundImage: `url(${val.id > 0 ? (val.banner && val.banner != '' ? val.banner : category1) : allItems})` }}
            >
              <Typography className="text-2xl text-center text-white capitalize font-semibold poppins">
                {val.name}
              </Typography>
              <Button component={Link} to={val.id > 0 ? '/category?parent_id=' + val.id : '/category?type=all'} className="text-white rounded-md bg-[#2858a3] uppercase poppins py-1 px-4 mt-3 font-semibold text-[10px]">
                Shop Now
              </Button>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Box>
  );
}
export default CategorySlider
