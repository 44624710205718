import React from "react";
import { Box, Typography } from "@mui/material";
import { Hero } from "../components";
import bg from "../assets/about_hero.jpg";

const Policy = () => {
  const keys = [
    {
      title: " Warranty Coverage",
      discription: " Eligible Products: On selected products where Buraq Star is the seller, we offer a twelve (12) month warranty for items purchased by buyers within the UAE. This warranty covers defects in materials, design, and workmanship."
    },
    {
      title: "Warranty Claim Process",
      child: [
        {
          title: " Registered Address",
          discription: "The address provided during the warranty claim request will be registered as the pick-up and delivery address for the claimed item. This address cannot be modified once registered.."
        },
        {
          title: " Service Centers",
          discription: " Warranty repairs will be carried out exclusively by our authorized service centers.Original Invoice: The original invoice is required to verify and validate the warranty period to benefit from warranty services"
        },
      ]
    },
    {
      title: "Warranty Repair Period",
      discription: "Repair Duration: The warranty repair period is fourteen (14) working days for units covered under the warranty, starting from the date of product pick-up from the customer until dispatch."
    },
    {
      title: "Replacement and Refund",
      discription: "Replacement or Refund: If your item cannot be repaired and is still under warranty, we will issue a replacement. If a replacement is not available, a full refund will be provided."
    },
    {
      title: "Claim Request",
      discription: "Raising a Claim: To avail of warranty services, if a defect occurs within the valid warranty period, you can raise a request through our customer service email: ecommerce@buraqstar.com. The BSTC warranty team will contact you within two (2) working days to validate the claim."
    },
    {
      title: "Packaging for Transit",
      discription: "Packaging Requirements: The customer must ensure that the item is packed in its original box or wrapped securely to avoid damage during transit. BSTC will not be responsible for damages caused by inadequate packaging, and the claimed item will be returned to the customer without servicing."
    },
    {
      title: "Contact Information",
      discription: "Customer Support: You may contact us via email at ecommerce@buraqstar.com or by calling our call center at 800-NOVEX (UAE)."
    },

  ];
  return (
    <Box className="w-full h-auto bg-white m-0 p-0">
      <Hero bg={bg} title={"Privacy Policy"} />
      <Box className="w-full h-fit py-10 px-10 sm:px-16">
        <Box className="borderCSS">
          <Typography className="poppins text-xl font-semibold text-[#2E2E2E] capitalize py-2">
            Buraq Star Warranty Policy
          </Typography>
          <Typography className="text-xs poppins text-[#152E3A] pb-2">
            At Buraq Star (BSTC), we are committed to ensuring that our customers receive the highest quality products and services. Our warranty policy is designed to protect your purchase against defects in material, design, and workmanship. The following outlines our warranty policy and procedures:
          </Typography>
          {keys.map((key, i) => (
            <Box key={i} className="pb-4">
              <Typography className="text-xs poppins text-[#152E3A]">
                <strong className="paragraphT">{i + 1}. {key.title}:</strong>
                <p className="paragraphD">{key.discription}</p>
                {key.child && key.child.map((child, j) => (
                  <Box key={j} className="ml-4">
                    <Typography className="text-xs poppins text-[#152E3A]">
                      <strong className="childT">{child.title}</strong>
                      <p className="childD">{child.discription}</p>
                    </Typography>
                  </Box>
                ))}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default Policy;