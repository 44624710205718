import React, { useEffect, useState, useMemo, useCallback, Link } from 'react'
import {
  Box,
  Button,
  ButtonBase,
  CircularProgress,
  Divider,
  Fade,
  Grid,
  ListItem,
  ListItemButton,
  Typography,
  IconButton,
  ListItemText,
  Skeleton
} from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  KeyboardArrowUpOutlined as ArrowUpIcon,
  KeyboardArrowDownOutlined as ArrowDownIcon,
  ViewColumn as ViewColumnIcon,
  FormatListBulleted as ListIcon,
  ExpandMore,
  ExpandLess,
  Remove,
  Add
} from '@mui/icons-material'
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import bg from '../assets/category_hero.jpg'
import ajaxService from '../services/ajax-service'
import { CATEGORIES, ImageURL } from '../constants'
import { Hero, BrandBanner, NewArrivals, AddToWishlist, AddToCartModal } from '../components'
import useScrollAnimationProducts from '../hooks/ScrollAnimationProducts';

const RenderGridProducts = React.memo(({ sortedProducts, navigate, handleModalClick }) => {
  const [refs, getClass] = useScrollAnimationProducts(
    sortedProducts.length,
    "opacity-10 translate-y-10",  // Initial state: low opacity and slight downward translation
    "opacity-100 translate-y-0 transition-all duration-700 ease-out hover:scale-105 hover:rotate-1"  // Combined transition with hover effects
  );

  return (
    <>
      {sortedProducts.map((prod, i) => (
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          ref={(el) => refs.current[i] = el}
          className={`px-2.5 ${getClass(i)}`}
          // style={{ transitionDelay: `${i * 0.1}s` }}
          style={{ transition: "transform 0.7s ease-in, opacity 0.7s ease-in" }}

          key={prod.id || i}
        >
          <ButtonBase
            onClick={() => navigate('/product/' + prod.slug)}
            sx={{ width: '100%' }}
          >
            <Box className="w-[41vh] h-96 products-box">
              <Box className="bg-white relative h-[70%] w-full flex justify-center items-center rounded-2xl">
                <div className="image-div">
                  <img
                    className="h-[75%] w-auto hover-image2"
                    src={ImageURL + (prod.images[0] ?? "")}
                    alt={prod.name}
                  />
                  <img
                    className="h-[75%] w-auto object-fit hover-img"
                    src={prod.images[1] ? ImageURL + prod.images[1] : ImageURL + prod.images[0]}
                    alt={prod.name}
                  />
                </div>
                <Box className="absolute top-2.5 px-2.5 flex w-full h-fit justify-between">
                  {prod.hot && (
                    <Box className="bg-[#FF0F0F] px-2 py-1 uppercase poppins text-white text-xs h-[24px]">
                      Hot
                    </Box>
                  )}
                  <AddToWishlist
                    product={prod}
                    products={[]}
                    setProducts={() => { }}
                    viaCategory={true}
                    open={false}
                    setOpen={() => { }}
                  />
                </Box>

                <Box className="invisible sm:visible absolute bottom-2.5 left-0 px-2.5 flex w-full h-fit justify-end">
                  <IconButton onClick={(e) => handleModalClick(e, prod)}>
                    <AddShoppingCartIcon className="text-gray-600 text-xl" />
                  </IconButton>
                </Box>
              </Box>
              <Box className="text-center py-6 px-3">
                <Typography className="poppins uppercase text-xs">
                  {prod.name}
                </Typography>
                <Box className="flex justify-center gap-x-4 pt-1">
                  {prod.discount_price && (
                    <Typography className="poppins uppercase text-sm text-[#FF0F0F]">
                      {prod.currency} {prod.discount_price}
                    </Typography>
                  )}
                  <Typography
                    className={`poppins uppercase text-md font-bold ${prod.discount_price && "line-through"}`}
                  >
                    {prod.currency} {prod.price}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </ButtonBase>
        </Grid>
      ))}
    </>
  );
});

const RenderListProducts = React.memo(({ sortedProducts, navigate, handleModalClick }) => {
  const [refs, getClass] = useScrollAnimationProducts(
    sortedProducts.length,
    "opacity-10 translate-y-10",  // Initial state: low opacity and slight downward translation
    "opacity-100 translate-y-0 transition-transform transition-opacity duration-700 ease-out hover:scale-105  hover:rotate-1"  // Final state with hover effects
  );

  return (
    <>
      {sortedProducts.map((prod, i) => (
        <Grid
          item
          xs={12}
          ref={(el) => refs.current[i] = el}
          className={getClass(i)}
          style={{ transition: "transform 0.7s ease-out, opacity 0.7s ease-out" }}
          key={prod.id || i}
        >
          <ButtonBase
            onClick={() => navigate('/product/' + prod.slug)}
            sx={{ width: '100%' }}
          >
            <Box className="flex items-center p-4 border-b border-gray-200 justify-between w-full text-left hover:bg-gray-50 transition-colors">
              <Box className="flex-shrink-0 w-24 h-24">
                <div className="relative w-full h-full">
                  <img
                    className="w-full h-full object-cover rounded-lg transition-transform duration-200 ease-in-out transform hover:scale-105"
                    src={ImageURL + (prod.images[0] ?? "")}
                    alt={prod.name}
                  />
                  <img
                    className="absolute top-0 left-0 w-full h-full object-cover rounded-lg transition-opacity duration-200 ease-in-out opacity-0 hover:opacity-100"
                    src={prod.images[1] ? ImageURL + prod.images[1] : ImageURL + prod.images[0]}
                    alt={prod.name}
                  />
                </div>
              </Box>
              <Box className="ml-4 flex-grow">
                <Typography className="poppins font-semibold text-lg text-gray-800">
                  {prod.name}
                </Typography>
                <Box className="flex justify-between items-center mt-2">
                  <Box className="flex flex-col">
                    {prod.discount_price && (
                      <Typography className="poppins text-sm text-red-600">
                        {prod.currency} {prod.discount_price}
                      </Typography>
                    )}
                    <Typography
                      className={`poppins text-sm ${prod.discount_price ? "line-through text-gray-500" : "text-black"}`}
                    >
                      {prod.currency} {prod.price}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box className="ml-auto flex items-center space-x-4">
                {prod.hot && (
                  <Box className="bg-red-600 px-3 py-1 uppercase poppins text-white text-xs rounded-full">
                    Hot
                  </Box>
                )}
                <AddToWishlist
                  product={prod}
                  products={[]}
                  setProducts={() => { }}
                  viaCategory={true}
                  open={false}
                  setOpen={() => { }}
                />
                <IconButton onClick={(e) => handleModalClick(e, prod)}>
                  <AddShoppingCartIcon className="text-gray-600 cursor-pointer hover:text-gray-800" />
                </IconButton>
              </Box>
            </Box>
          </ButtonBase>
        </Grid>
      ))}
    </>
  );
});


const Sidebar = React.memo(
  ({
    toggleExpand,
    expandedItems,
    sideItems,
    handleParentClick,
    handleChildClick,
    handleSubChildClick,
    sidebarLoading,
    Title,
  }) => {

    const getListItemStyles = (isActive) => ({
      borderLeft: isActive ? "5px solid #2858a3" : "inherit",
      borderRadius: isActive ? "0px" : "10px",
      marginTop: isActive ? "5px" : "0px",
      paddingY: isActive ? "0px" : "0px",
      "&:hover": {
        backgroundColor: isActive
          ? "rgb(85 137 218 / 40%)"
          : "rgba(0, 0, 0, 0.04)",
        borderRadius: isActive ? "10px" : "10px",
      },
    });

    const getListItemIconStyle = (isActive) => ({
      color: isActive ? "#2858a3" : "inherit",
    });

    const getSubListItemStyles = (isActive) => ({
      borderLeft: isActive ? "5px solid #2858a3" : "inherit",
      borderRadius: isActive ? "0px" : "10px",
      marginTop: isActive ? "5px" : "2px",
      "&:hover": {
        backgroundColor: isActive
          ? "rgb(85 137 218 / 40%)"
          : "rgba(0, 0, 0, 0.04)",
        borderRadius: isActive ? "10px" : "10px",
      },
    });

    const fadeSlideClasses = (isExpanded) =>
      isExpanded
        ? "opacity-100 translate-y-0 max-h-[1000px] transition-all duration-100 ease-in-out"
        : "opacity-0 -translate-y-4 max-h-0 transition-all duration-100 ease-in-out pointer-events-none";

    const sidebarAnimationClass = sidebarLoading
      ? "translate-x-[-20px]"
      : " translate-x-0 transition-all duration-1000 ease-out";

    return (
      <Grid
        item
        xs={12}
        sm={3}
        className={`${sidebarAnimationClass}`}
      >
        <Box className="sticky top-10">
          <Typography variant="h4" className="poppins pb-10 text-center">
            {Title}
          </Typography>

          <Grid item xs={12} className="px-2.5 mb-5">
            {sidebarLoading ? (
              Array.from(new Array(5)).map((_, index) => (<>
                <Box p={2} width={300}>
                  {/* View All Items */}
                  <Skeleton variant="text" width={120} height={30} style={{ marginBottom: "10px" }} />
                  <Skeleton variant="rectangular" width="100%" height={1} style={{ marginBottom: "20px" }} />

                  {/* First Category */}
                  <Skeleton variant="text" width={80} height={25} style={{ marginBottom: "10px" }} />

                  {/* Subcategory and Items */}
                  <Box ml={2}>
                    <Skeleton variant="text" width={60} height={20} style={{ marginBottom: "10px" }} />

                    <Box ml={2}>
                      {Array.from(new Array(6)).map((_, index) => (
                        <Skeleton key={index} variant="text" width={180} height={20} style={{ marginBottom: "10px" }} />
                      ))}
                    </Box>
                  </Box>
                </Box>
              </>
              ))
            ) : (
              <>
                {sideItems.map((item, i) => (
                  <Box key={i} className="mb-1">
                    <ListItem
                      disablePadding
                      sx={getListItemStyles(item.active)}
                    >
                      <ListItemButton
                        onClick={() =>
                          handleParentClick(item.id ?? item.slug, item.name)
                        }
                      >
                        <ListItemText
                          primary={item.name}
                          primaryTypographyProps={{
                            className: "poppins text-lg",
                          }}
                        />
                      </ListItemButton>
                      {item.childrens && item.childrens.length > 0 && (
                        <IconButton
                          onClick={() => toggleExpand(item.id)}
                          size="small"
                        >
                          {expandedItems[item.id] ? (
                            <Remove sx={getListItemIconStyle(item.active)} />
                          ) : (
                            <Add sx={getListItemIconStyle(item.active)} />
                          )}
                        </IconButton>
                      )}
                    </ListItem>
                    <div
                      className={`overflow-hidden ${fadeSlideClasses(
                        expandedItems[item.id]
                      )}`}
                    >
                      {item.childrens &&
                        item.childrens.length > 0 &&
                        item.childrens.map((val, index) => (
                          <React.Fragment key={index}>
                            <ListItem disablePadding sx={{ pl: 4 }}>
                              <Box
                                display={"flex"}
                                className="w-full"
                                sx={getSubListItemStyles(val.active)}
                              >
                                <ListItemButton
                                  onClick={() =>
                                    handleChildClick(item.id, val.id, val.name)
                                  }
                                >
                                  <ListItemText
                                    primary={val.name}
                                    primaryTypographyProps={{
                                      className: "poppins text-xs uppercase",
                                    }}
                                  />
                                </ListItemButton>

                                {val.childrens &&
                                  val.childrens.length > 0 && (
                                    <IconButton
                                      onClick={() => toggleExpand(val.id)}
                                      size="small"
                                    >
                                      {expandedItems[val.id] ? (
                                        <Remove
                                          sx={{
                                            ...getListItemIconStyle(
                                              item.active
                                            ),
                                            fontSize: "15px",
                                          }}
                                        />
                                      ) : (
                                        <Add
                                          sx={{
                                            ...getListItemIconStyle(
                                              item.active
                                            ),
                                            fontSize: "15px",
                                          }}
                                        />
                                      )}
                                    </IconButton>
                                  )}
                              </Box>
                            </ListItem>
                            <div
                              className={`overflow-hidden ${fadeSlideClasses(
                                expandedItems[val.id]
                              )}`}
                            >
                              {val.childrens &&
                                val.childrens.length > 0 &&
                                val.childrens.map((subItem, x) => (
                                  <ListItem
                                    key={x}
                                    disablePadding
                                    sx={{ pl: 8 }}
                                  >
                                    <ListItemButton
                                      onClick={() =>
                                        handleSubChildClick(
                                          item.id,
                                          val.id,
                                          subItem.id,
                                          subItem.name
                                        )
                                      }
                                      sx={getSubListItemStyles(
                                        subItem.active
                                      )}
                                    >
                                      <ListItemText
                                        primary={subItem.name}
                                        primaryTypographyProps={{
                                          className:
                                            "poppins text-xs pl-5 uppercase",
                                        }}
                                      />
                                    </ListItemButton>
                                  </ListItem>
                                ))}
                            </div>
                          </React.Fragment>
                        ))}
                    </div>
                    {i + 1 !== sideItems.length && (
                      <Divider
                        sx={{
                          backgroundColor: "#d9d9d9",
                          my: 2,
                          ml: 3,
                          width: "80%",
                        }}
                      />
                    )}
                  </Box>
                ))}
              </>
            )}
          </Grid>
        </Box>
      </Grid>
    );
  }
);



const Products = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const token = localStorage.getItem('token')
  const user = localStorage.getItem('user') ?? null

  const Type = queryParams.get('type')
  const parentID = queryParams.get('parent_id')
  const childID = queryParams.get('child_id')
  const subChildID = queryParams.get('sub_child_id')
  const brandID = queryParams.get('brand_id')

  const [sortOn, setSortOn] = useState(false)
  const [sortingOn, setSortingOn] = useState(false)
  const [productsLoading, setProductsLoading] = useState(true)
  const [sidebarLoading, setSidebarLoading] = useState(true)
  const [open, setOpen] = useState(false)

  const [sideItems, setSideItems] = useState([])
  const [products, setProducts] = useState([])
  const [offset, setOffset] = useState(0)
  const [count, setCount] = useState(0)
  const [loading, setLoading] = useState(false)
  const [isGridView, setIsGridView] = useState(true)
  const [sortOption, setSortOption] = useState(null)

  const [viewMode, setViewMode] = useState('grid') // Default view mode

  const [priceSortText, setPriceSortText] = useState('Sort By Price')
  const [alphaSortText, setAlphaSortText] = useState('Sort By Alphabet')

  const newArrivals = Type === CATEGORIES.NEW_ARRIVAL
  const dealsConst = !newArrivals && Type && Type !== CATEGORIES.ALL

  const [cartModal, setCartModal] = useState(false);
  const [modalProduct, setModalProduct] = useState({});

  const handleModalClick = (e, product) => {
    e.stopPropagation();
    setCartModal(true);
    setModalProduct(product);
  }

  const Title = useMemo(
    () =>
      newArrivals
        ? 'New Arrivals'
        : brandID
          ? 'Brands'
          : dealsConst
            ? 'Deals'
            : 'Categories',
    [newArrivals, brandID, dealsConst]
  )
  const [heroTitle, setHeroTitle] = useState('Categories')

  const [expandedItems, setExpandedItems] = useState({})

  const toggleExpand = id => {
    setExpandedItems(prevState => ({
      ...prevState,
      [id]: !prevState[id]
    }))

  }

  const handleParentClick = useCallback(
    (id, title = null) => {
      setHeroTitle(title || 'Categories')
      navigate(
        brandID
          ? `?brand_id=${id}`
          : dealsConst
            ? `?type=${id}`
            : id > 0
              ? `?parent_id=${id}`
              : '?type=all'
      )
    },
    [navigate, brandID, dealsConst]
  )

  const handleChildClick = useCallback(
    (parentId, id, title = null) => {
      setHeroTitle(title || 'Categories')
      navigate(`/category?parent_id=${parentId}&child_id=${id}`)
    },
    [navigate]
  )

  const handleSubChildClick = useCallback(
    (parentId, childId, id, title = null) => {
      setHeroTitle(title || 'Categories')
      navigate(
        `/category?parent_id=${parentId}&child_id=${childId}&sub_child_id=${id}`
      )
    },
    [navigate]
  )

  const loadCategories = useCallback(async () => {
    const response = await ajaxService.get('/all-categories')
    if (response.success) {
      let data = response.data
      const updatedCategories = [{ id: 0, name: 'View All Items' }, ...data]

      const categoriesList = updatedCategories.map(i => {
        i.active = Type === CATEGORIES.ALL ? i.id === 0 : i.id == parentID


        if (i.childrens) {
          i.childrens.map(c => {
            c.active = c.id == childID

            if (c.childrens) {
              c.childrens.map(x => {
                x.active = x.id == subChildID
                return x
              })
            }
            return c

          })
        }
        return i
      })

      setSidebarLoading(false)
      setSideItems(categoriesList)
    }
  }, [Type, parentID, childID, subChildID])

  const loadDeals = useCallback(async () => {
    const { success, data } = await ajaxService.get('/all-deals')
    if (success) {
      data.map(i => {
        i.active = i.slug === Type
        return i
      })
      setSidebarLoading(false)
      setSideItems(data)
    }
  }, [Type])

  const loadBrands = useCallback(async () => {
    const { success, data } = await ajaxService.get('/all-brands')
    if (success) {
      data.map(i => {
        i.active = i.id === brandID
        return i
      })
      setSidebarLoading(false)
      setSideItems(data)
    }
  }, [brandID])

  const fetchProducts = useCallback(
    async (updatedOffset = 0) => {
      let type = CATEGORIES.ALL
      let id = 0

      if (subChildID) {
        type = CATEGORIES.SUB_CHILD
        id = subChildID
      } else if (childID) {
        type = CATEGORIES.CHILD
        id = childID
      } else if (parentID) {
        type = CATEGORIES.PARENT
        id = parentID
      }

      const baseUrl = '/category/products'

      const queryParams = new URLSearchParams({
        category_id: id,
        type: type,
        offset: updatedOffset,
        brand_id: brandID ?? 0,
        new_arrival: newArrivals
      })

      if (dealsConst) {
        queryParams.append('product_type', Type)
      }

      if (token && user) {
        queryParams.append('user_id', JSON.parse(user).id)
      }

      const url = `${baseUrl}?${queryParams.toString()}`
      const response = await ajaxService.get(url)
      return response ? response : null
    },
    [
      subChildID,
      childID,
      parentID,
      brandID,
      newArrivals,
      dealsConst,
      Type,
      token,
      user
    ]
  )

  const handleLoadMoreClick = useCallback(async () => {
    const updatedOffset = offset + 15
    setOffset(updatedOffset)
    setLoading(true)

    const response = await fetchProducts(updatedOffset)

    if (response) {
      setLoading(false)
      setProductsLoading(false) // false
      setProducts(prevProducts => [...prevProducts, ...response.data])

      // const loadMoreButton = document.getElementById('loadMoreButton')
      // loadMoreButton.scrollIntoView({ behavior: 'smooth' })
    }
  }, [offset, fetchProducts])

  const loadProducts = useCallback(async () => {
    setOffset(0)
    const response = await fetchProducts()

    if (response) {
      setCount(response.count)
      setProductsLoading(false) // false
      setProducts(response.data)
    }
  }, [fetchProducts])

  const handleSortOptionChange = useCallback(
    option => {
      setSortOption(option)
      setProductsLoading(true)
      setProducts([])
      setOffset(0)
      loadProducts()

      // Update the button text based on the selected option
      if (option === 3) {
        setPriceSortText('Sort By Low to High')
      } else if (option === 4) {
        setPriceSortText('Sort By High to Low')
      } else if (option === 1) {
        setAlphaSortText('Sort By A to Z')
      } else if (option === 2) {
        setAlphaSortText('Sort By Z to A')
      }
    },
    [loadProducts]
  )

  useEffect(() => {
    setOffset(0)
    setProductsLoading(true)
    setProducts([])
    if (brandID) {
      loadBrands()
    } else if (dealsConst) {
      loadDeals()
    } else {
      loadCategories()
    }
    loadProducts()
  }, [
    parentID,
    childID,
    subChildID,
    location.pathname,
    brandID,
    Type,
    sortOption,
    loadBrands,
    loadDeals,
    loadCategories,
    loadProducts
  ])

  useEffect(() => {
    // Runs only on initial mount
    if (parentID) {
      toggleExpand(parentID);
    }
    if (childID) {
      toggleExpand(childID);
    }
    if (subChildID) {
      toggleExpand(subChildID);
    }
  }, []);

  const sortedProducts = useMemo(
    () =>
      products.sort((a, b) => {
        switch (sortOption) {
          case 1:
            return a.name.localeCompare(b.name) // Ascending by name
          case 2:
            return b.name.localeCompare(a.name) // Descending by name
          case 3:
            return a.price - b.price // Low to high price
          case 4:
            return b.price - a.price // High to low price
          default:
            return 0
        }
      }),
    [products, sortOption]
  )

  const handleViewModeChange = useCallback(mode => {
    setViewMode(mode)
  }, [])

  return (
    <Box className='w-full h-auto m-0 p-0'>
      <Hero bg={bg} title={heroTitle} />
      <Grid container className='py-16 px-10 md:px-14 lg:px-36'>
        {!newArrivals && (
          <Sidebar
            expandedItems={expandedItems}
            toggleExpand={toggleExpand}
            sideItems={sideItems}
            handleParentClick={handleParentClick}
            handleChildClick={handleChildClick}
            handleSubChildClick={handleSubChildClick}
            sidebarLoading={sidebarLoading}
            Title={Title}
          />
        )}
        <Grid item xs={12} sm={newArrivals ? 12 : 9}>
          <Box className='hidden sm:block sticky top-0 z-[1] bg-[#f5f5f5] py-2'>
            <Box className='flex justify-between items-center'>
              <Box className='flex g-20p'>
                <Box className='relative'>
                  <Button
                    variant='outlined'
                    onClick={() => setSortOn(!sortOn)}
                    className='w-fit bg-transparent rounded-none border-black border text-black uppercase text-sm poppins '
                  >
                    {priceSortText}
                    {sortOn ? (
                      <ArrowUpIcon className='text-base ms-2' />
                    ) : (
                      <ArrowDownIcon className='text-base ms-2' />
                    )}
                  </Button>
                  {sortOn && (
                    <Box className='absolute gap-y-3 p-2.5 z-50 top-8 w-full left-0 bg-white border'>
                      {[3, 4].map(index => (
                        <Typography
                          key={index}
                          className='text-sm text-black poppins cursor-pointer'
                          onClick={() => handleSortOptionChange(index)}
                        >
                          {index === 3 ? 'Low to High' : 'High to Low'}
                        </Typography>
                      ))}
                    </Box>
                  )}
                </Box>
                <Box className='relative'>
                  <Button
                    variant='outlined'
                    onClick={() => setSortingOn(!sortingOn)}
                    className='w-fit bg-transparent rounded-none border-black border text-black uppercase text-sm poppins '
                  >
                    {alphaSortText}
                    {sortingOn ? (
                      <ArrowUpIcon className='text-base ms-2' />
                    ) : (
                      <ArrowDownIcon className='text-base ms-2' />
                    )}
                  </Button>
                  {sortingOn && (
                    <Box className='absolute gap-y-3 p-2.5 z-50 top-8 w-full left-0 bg-white border'>
                      {[1, 2].map(index => (
                        <Typography
                          key={index}
                          className='text-sm text-black poppins cursor-pointer'
                          onClick={() => handleSortOptionChange(index)}
                        >
                          {index === 1 ? 'A to Z' : 'Z to A'}
                        </Typography>
                      ))}
                    </Box>
                  )}
                </Box>
              </Box>
              <Box className='w-fit flex gap-x-3'>
                <Typography
                  className='text-xs text-black cursor-pointer'
                  onClick={() => setIsGridView(true)}
                >
                  <ViewColumnIcon />
                </Typography>
                <Typography
                  className='text-xs text-black cursor-pointer'
                  onClick={() => setIsGridView(false)}
                >
                  <ListIcon />
                </Typography>
              </Box>
            </Box>
          </Box>

          <Divider
            className='sm:hidden'
            sx={{
              backgroundColor: "#d9d9d9",
              my: 2,
              width: "100%",
            }}
          />

          <Grid container className="mt-10">

            {productsLoading ? (
              <Grid item xs={12} className="px-2.5 mb-5 text-center">
                <Box display="flex" justifyContent="space-between" flexWrap="wrap" gap={2}>
                  {[...Array(6)].map((_, index) => (
                    <Box
                      key={index}
                      className="flex flex-col items-center"
                      style={{
                        width: "calc(33.333% - 16px)",
                        borderRadius: "12px",
                        marginBottom: "16px",
                      }}
                    >
                      {/* Heart Icon Placeholder */}
                      <Skeleton
                        variant="circular"
                        width={24}
                        height={24}
                        style={{ alignSelf: "flex-start", marginBottom: "-28px", marginLeft: "8px" }}
                      />
                      {/* Product Image Placeholder */}
                      <Skeleton
                        variant="rectangular"
                        width="100%"
                        animation="wave"
                        height={240}
                        style={{
                          borderRadius: "8px",
                          marginBottom: "16px",
                        }}
                      />
                      {/* Cart Icon Placeholder */}
                      <Skeleton
                        variant="circular"
                        width={24}
                        height={24}
                        style={{ alignSelf: "flex-end", marginTop: "-45px", marginRight: "8px", marginBottom: "8px", }}
                      />
                      {/* Product Title Placeholder */}
                      <Skeleton
                        variant="text"
                        width="80%"
                        height={20}
                        style={{
                          borderRadius: "4px",
                          marginBottom: "8px",
                        }}
                      />
                      {/* Product Price Placeholder */}
                      <Skeleton
                        variant="text"
                        width="40%"
                        height={20}
                        style={{
                          borderRadius: "4px",
                          marginBottom: "8px",
                        }}
                      />

                    </Box>
                  ))}
                </Box>
              </Grid>
            ) : (
              <> {products.length === 0 && !productsLoading ? (
                <Grid item xs={12} className="px-2.5 mb-5 text-center">
                  This Category has no products {':)'}
                </Grid>
              ) : isGridView ? (
                <RenderGridProducts
                  sortedProducts={sortedProducts}
                  navigate={navigate}
                  handleModalClick={handleModalClick}
                />
              ) : (
                <RenderListProducts
                  sortedProducts={sortedProducts}
                  navigate={navigate}
                  handleModalClick={handleModalClick}
                />
              )}

                {products.length < count && !productsLoading && (
                  <Grid item xs={12} className="flex justify-center h-fit">
                    <Button
                      id="loadMoreButton"
                      className="bg-black text-white text-xs cursor-pointer poppins uppercase p-4 rounded-none"
                      onClick={handleLoadMoreClick}
                      disabled={loading}
                    >
                      {loading && <CircularProgress size={18} className="text-white mr-2" />}
                      Load More
                    </Button>
                  </Grid>
                )}</>
            )}
          </Grid>


          {Object.keys(modalProduct)?.length > 0 && (
            <AddToCartModal
              visible={cartModal}
              setVisible={setCartModal}
              product={modalProduct}
            />
          )}
        </Grid>
      </Grid>
      <BrandBanner />
      <NewArrivals />
    </Box>
  )
}

export default Products
