import React from "react";
import { Typography } from "@mui/material";
import DOMPurify from 'dompurify';
import '../../App.css'; // Import your Tailwind CSS styles

const BlogLayout = ({ blog }) => {
  // Sanitize HTML content
  const sanitizedDescription = DOMPurify.sanitize(blog?.description);
  // Constants for Typography variants and Tailwind CSS classes
  const TAG_MAPPING = {
    h1: { variant: "h1", className: "poppins font-bold text-3xl mt-4 mb-2" },
    h2: { variant: "h2", className: "poppins font-semibold text-2xl mt-4 mb-2" },
    h3: { variant: "h3", className: "poppins font-semibold text-xl mt-4 mb-2" },
    h4: { variant: "h4", className: "poppins font-semibold text-lg mt-4 mb-2" },
    h5: { variant: "h5", className: "poppins font-semibold text-base mt-4 mb-2" },
    p: { variant: "body1", className: "poppins text-base mt-2 mb-2" },
    ul: { variant: "h3", className: " list-disc list-inside mb-2" },
    li: { variant: "h4", className: "poppins text-base mb-1" },
  };

 // Function to render each HTML tag dynamically
 const renderTag = (node, index) => {
  const tag = node.tagName.toLowerCase();
  const { variant, className } = TAG_MAPPING[tag] || {};

  if (variant && className) {
    return (
      <Typography key={index} variant={variant} className={className}>
        {node.innerHTML}
      </Typography>
    );
  } else if (tag === "ul") {
    return (
      <ul key={index} className={className}>
        {node.innerHTML}
      </ul>
    );
  } else if (tag === "li") {
    return (
      <li key={index} className={className}>
        {node.innerHTML}
      </li>
    );
  } else {
    return null; // Handle other tags as needed
  }
};

  // Parse sanitized HTML and render mapped components
  const renderHTML = () => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(sanitizedDescription, "text/html");
    const nodes = Array.from(doc.body.childNodes);

    // Remove all <br> tags from the end of the content
    let lastIndex = nodes.length - 1;
    while (lastIndex >= 0 && nodes[lastIndex].nodeName.toLowerCase() === "br") {
      lastIndex--;
    }

    // Filter out nodes up to the last valid index (excluding <br> tags)
    const filteredNodes = nodes.slice(0, lastIndex + 1);

    return filteredNodes.map((node, index) => {
      return renderTag(node, index);
    });
  };

  return (
    <>
      <Typography className="poppins font-normal capitalize text-4xl text-[#25252A] pb-8">
        {blog?.title}
      </Typography>
      <img className="w-full h-auto" src={blog?.banner} alt="blog bg" />
      <Typography className="poppins text-base text-black py-6">
        {blog?.created_at}
      </Typography>
      {renderHTML()}
    </>
  );
};

export default BlogLayout;
