import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Typography, Divider } from "@mui/material";
import {
  CitySelect,
  CountrySelect,
  StateSelect,
} from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
// import axios from "axios";
// import { BASE_URL } from "../../constants";
import { AuthContext } from "../../AuthContext";
import axios from "axios";
import { BASE_URL } from "../../constants";

const CheckoutForm = ({
  formData,
  setFormData,
  errors,
  setErrors,
  setShippingCost
}) => {
  const [countryid, setCountryid] = useState(0);
  const [stateid, setstateid] = useState(0);
  const { isLoggedIn } = useContext(AuthContext)

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    // Clear previous error message when user starts typing
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const handleChangeLocation = (event, field) => {
    const { id ,name} = event;
    if (field === "country") {
      setFormData({ ...formData, country: name, state: '', city: '' });
    } else if (field === "state") {
      setFormData({ ...formData, state: name, city: '' });
    } else if (field === "city") {
      axios.post(`${BASE_URL}/api/v1/checkout/get-shipping-cost`, {
        city: event
      })
        .then((res) => {
          console.log("res", res.data.standard_delivery_cost)
          setShippingCost(res.data.standard_delivery_cost)
        })
        .catch((error) => {
          console.error("Error:", error);
        });
      setFormData({ ...formData, city: name });
    }
  };

  // useEffect(()=> {
  //   axios.get(`${BASE_URL}/api/v1/all-countries`, {},{
  //       headers: {
  //           Authorization: `Bearer ${localStorage?.getItem('token')}`
  //       },
  //   })
  //   .then((res) => {
  //       console.log("res", res)
  //   })
  //   .catch((error) => {
  //     console.error("Error:", error);
  //   });
  // }, [])

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        background: "#fff",
        borderRadius: "4px",
        marginBottom: "12px",
        paddingBottom: "15px",
      }}
      role="presentation"
    >
      <Box
        className="p-5"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <p style={{ fontWeight: 900, fontSize: "px" }}>Delivery Address</p>
      </Box>
      {/* <Divider /> */}

      <Box className="w-full h-fit p-5 mt-8 sm:mt-0 rounded-md flex flex-col items-center gap-4">
        <Box className="flex flex-col items-start w-full gap-1">
          <Typography className="poppins font-semibold uppercase text-xs text-black">
            First Name<span className="text-[red]">*</span>
          </Typography>
          <input
            type="text"
            id="fullName"
            name="fullName"
            value={formData.fullName}
            onChange={handleChange}
            className="text-black bg-white rounded-md w-full text-[14px] outline-none poppins px-3 py-2.5 border border-[#cccccc]"
            placeholder="Enter your first name"
            required
          />
        </Box>
        <Box className="flex flex-col items-start w-full gap-1">
          <Typography className="poppins font-semibold uppercase text-xs text-black">
            Last Name<span className="text-[red]">*</span>
          </Typography>
          <input
            type="text"
            id="lastName"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            className="text-black bg-white rounded-md w-full text-[14px] outline-none poppins px-3 py-2.5 border border-[#cccccc]"
            placeholder="Enter your last name"
            required
          />
        </Box>
        <Box className="flex flex-col items-start w-full gap-1">
          <Typography className="poppins font-semibold uppercase text-xs text-black">
            Email Address<span className="text-[red]">*</span>
          </Typography>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="text-black bg-white rounded-md w-full text-[14px] outline-none poppins px-3 py-2.5 border border-[#cccccc]"
            placeholder="Enter your email"
            required
          />
        </Box>
        {!isLoggedIn ? <Box className="flex flex-col items-start w-full gap-1">
          <Typography className="poppins font-semibold uppercase text-xs text-black">
            Password<span className="text-[red]">*</span>
          </Typography>
          <input
            type="password"
            id="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            className="text-black bg-white rounded-md w-full text-[14px] outline-none poppins px-3 py-2.5 border border-[#cccccc]"
            placeholder="Enter your password"
          />
        </Box> : null}


        <Box className="flex flex-col items-start w-full gap-1">
          <Typography className="poppins font-semibold uppercase text-xs text-black">
            phone<span className="text-[red]">*</span>
          </Typography>
          <input
            type="text"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            className="text-black bg-white rounded-md w-full text-[14px] outline-none poppins px-3 py-2.5 border border-[#cccccc]"
            placeholder="Enter your phone"
          />
        </Box>
        <Box className="flex flex-col items-start w-full gap-1">
          <Typography className="poppins font-semibold uppercase text-xs text-black">
            Delivery Address<span className="text-[red]">*</span>
          </Typography>
          <input
            type="text"
            id="delivery_address"
            name="delivery_address"
            value={formData.delivery_address}
            onChange={handleChange}
            className="text-black bg-white rounded-md w-full text-[14px] outline-none poppins px-3 py-2.5 border border-[#cccccc]"
            placeholder="e.g. Unit 1, 123 Main Street"
          />
        </Box>
        <Box className="flex flex-col items-start w-full gap-1">
          <Typography className="poppins font-semibold uppercase text-xs text-black">
            Country<span className="text-[red]">*</span>
          </Typography>
          <Box className="w-full">
            <CountrySelect
              id="country"
              name="country"
              countryid={countryid}
              onChange={(e) => {
                setCountryid(e.id);
                handleChangeLocation(e, "country");
              }}
              placeHolder="Select Country"
              className="w-[100%]"
            />
          </Box>
        </Box>
        <Box className="flex flex-col items-start w-full gap-1">
          <Typography className="poppins font-semibold uppercase text-xs text-black">
            State<span className="text-[red]">*</span>
          </Typography>
          <Box className="w-full">
            <StateSelect
              id="state"
              name="state"
              countryid={countryid}
              onChange={(e) => {
                setstateid(e.id);
                handleChangeLocation(e, "state");
              }}
              placeHolder="Select State"
              className="w-[100%]"
            />
          </Box>
        </Box>
        <Box className="flex flex-col items-start w-full gap-1">
          <Typography className="poppins font-semibold uppercase text-xs text-black">
            City<span className="text-[red]">*</span>
          </Typography>
          <Box className="w-full">
            <CitySelect
              id="state"
              name="state"
              stateid={stateid}
              countryid={countryid}
              onChange={(e) => {
                handleChangeLocation(e, "city");
              }}
              placeHolder="Select City"
              className="w-[100%]"
            />
          </Box>
        </Box>
        <Box className="flex flex-col items-start w-full gap-1">
          <Typography className="poppins font-semibold uppercase text-xs text-black">
            Post Code/Zip code<span className="text-[red]">*</span>
          </Typography>
          <input
            type="text"
            id="zip_code"
            name="zip_code"
            value={formData.zip_code}
            onChange={handleChange}
            className="text-black bg-white rounded-md w-full text-[14px] outline-none poppins px-3 py-2.5 border border-[#cccccc]"
            placeholder="Zip Code"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default CheckoutForm;
