import React, { useState, useEffect } from 'react';
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import axios from 'axios';
import { Skeleton, Alert, Container, Box } from '@mui/material';
import StoreListComponent from '../components/StoreLocator/StoreListComponent';
import StoreDetailComponent from '../components/StoreLocator/StoreDetailComponent';

const mapStyles = {
  height: "100vh",
  width: "100%"
};

const defaultCenter = {
  lat: 42.3601, lng: -71.0589
};

const StoreLocator = () => {
  const [selectedStore, setSelectedStore] = useState(null);
  const [stores, setStores] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    axios.get('/path-to-your-store-locations-api-or-static-file')
      .then(response => {
        setStores(response.data);
        // setLoading(false);
      })
      .catch(error => {
        setError('Error fetching store data');
        // setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <Container>
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh" flexDirection="column">
          <Skeleton variant="rectangular" width="80%" height={400} />
          <Skeleton variant="rectangular" width="80%" height={60} style={{ marginTop: '20px' }} />
          <Skeleton variant="rectangular" width="80%" height={60} style={{ marginTop: '10px' }} />
          <Skeleton variant="rectangular" width="80%" height={60} style={{ marginTop: '10px' }} />
        </Box>
      </Container>
    );
  }

  if (error) {
    return (
      <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Alert severity="error">{error}</Alert>
      </Container>
    );
  }

  return (
    <div>
      <LoadScript 
        googleMapsApiKey="YOUR_GOOGLE_MAPS_API_KEY"
        onLoad={() => setLoading(false)}
        onError={() => {
          setError('Error loading Google Maps');
          setLoading(false);
        }}
      >
        <GoogleMap
          mapContainerStyle={mapStyles}
          zoom={13}
          center={defaultCenter}
        >
          {stores.map(store => (
            <Marker 
              key={store.id} 
              position={{ lat: store.latitude, lng: store.longitude }}
              onClick={() => setSelectedStore(store)}
            />
          ))}
          {selectedStore && (
            <InfoWindow
              position={{ lat: selectedStore.latitude, lng: selectedStore.longitude }}
              onCloseClick={() => setSelectedStore(null)}
            >
              <div>
                <h2>{selectedStore.name}</h2>
                <p>{selectedStore.address}</p>
                <StoreDetailComponent store={selectedStore} />
              </div>
            </InfoWindow>
          )}
        </GoogleMap>
      </LoadScript>
      <StoreListComponent stores={stores} onSelectStore={setSelectedStore} loading={loading} />
    </div>
  );
}

export default StoreLocator;
