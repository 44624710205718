
import { CartProvider } from "react-use-cart";
import "./App.css";
import AppRoutes from "./Routes";
import { AuthProvider } from "./AuthContext";
import { BrowserRouter } from "react-router-dom";

function App() {

  return (
    <AuthProvider>
      <CartProvider>
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
      </CartProvider>
    </AuthProvider>
  )
}

export default App;
