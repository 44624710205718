import novex from "./assets/novex.jpg";
import buraq from "./assets/buraqabout.png";
import cavil from "./assets/cavilabout.png";
import zilco from "./assets/zilcoabout.png";
import { BRANDS } from "./constants";

export function getBrandLogo(brand) {
    switch (brand) {
        case BRANDS.BURAQ:
            return buraq;
        case BRANDS.NOVEX:
            return novex;
        case BRANDS.CAVIL:
            return cavil;
        case BRANDS.ZILCO:
            return zilco;
        default:
            return null;
    }
}

export const routes = {
    home: '/',
    thankYou: '/thankyou',
    category: '/category',
    checkout: '/checkout',
    brand: '/brand',
    deals: '/deals',
    product: '/product/:slug?',
    about: '/about',
    terms: '/terms',
    policy: '/policy',
    blogs: '/blogs',
    blog: '/blog/:slug?',
    contact: '/contact',
    faq: '/faq',
    cart: '/cart',
    storeLocator: '/store-locator',
    login: '/user/login',
    registration: '/user/registration',
    verify: '/user/verify-account',
    wishlist: '/user/wishlists',
    dashboard: '/user/dashboard',
    myOrder: '/user/my-order',
    myProfile: '/user/profile',
  };
  
  export default routes;
  