import React from "react";
import { Box, Button, ButtonBase, Divider, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Aside = ({ latest }) => {

  const navigate = useNavigate()

  return (
    <Box className="px-4 w-full h-fit ">
      <Box className="w-full h-fit border-2 relative p-4 pt-10">
        <Box className="absolute -top-4 left-0 w-full flex justify-center">
          <Typography className="text-center w-fit px-4 bg-[#f5f5f5] text-black poppins text-xl ">
            Latest Posts
          </Typography>
        </Box>
        {latest?.map((blog, index) => (
          <ButtonBase onClick={() => navigate('/blog/' + blog.slug)} style={{ textAlign: 'left' }}>
            <Box key={index} className="flex pb-3">
              {/* <img src={blog.banner} className="h-20 w-20 object-cover" alt="blog" /> */}
              <img src={blog.banner} className="h-20" style={{ width: '100px', objectFit: 'cover' }} alt="blog" />
              <Box className="ps-2">
                <Typography className="text-black poppins text-xs capitalize pb-2 font-semibold ">
                  {blog.title}
                </Typography>
                <Typography className="text-black poppins text-xs ">
                  {blog.created_at}
                </Typography>
              </Box>
            </Box>
          </ButtonBase>
        ))}
      </Box>
      <Box className="mt-10 bg-[#F6F6F6] w-full h-fit p-5">
        <Typography className="text-center text-black poppins text-xl ">
          Newsletter
        </Typography>
        <Box className="flex justify-center w-full">
          <Divider className="bg-[#F5AC55] w-10 h-[2px] my-5" />
        </Box>
        <Typography className="text-center w-full px-2 text-black poppins text-sm ">
          Subscribe to our newsletter for exclusive content and all of the
          behind the scenes details.
        </Typography>
        <input
          type="email"
          className="bg-white outline-none p-3 text-black text-xs my-3 w-full"
          placeholder="Your Email Address"
        />
        <Button className="bg-[#F5AC55] w-full py-3 rounded-none text-xs text-white poppins">
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default Aside;
