import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Dropdown from 'react-multilevel-dropdown';
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ajaxService from "../../../services/ajax-service";

const Deals = ({ title }) => {

    const [dealsOpen, setDealsOpen] = useState(false);
    const [deals, setDeals] = useState([])

    const loadDeals = async () => {
        const { success, data } = await ajaxService.get('/all-deals');
        if (success) {
            setDeals(data);
        }
    }

    useEffect(() => {
        loadDeals();
    }, [])

    return (
        <Dropdown
            position="right"
            title={<>{title} {dealsOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}</>}
            className="poppins text-sm text-white flex items-center relative"
            onClick={() => setDealsOpen(!dealsOpen)}
            menuClassName="border-b-[3px] border-[#2858a3] rounded-b-md bg-[#f1f1f1] text-black h-fit absolute z-50 top-9 left-0 block"
        >
            {deals.map((val, i) => (
                <Dropdown.Item key={i} className={`w-full flex items-center justify-between gap-x-14`}>
                    <Link
                        to={`/deals?type=${val.slug}`}
                        className={`w-full flex items-center justify-between gap-x-14`}
                    >
                        {val.name}
                    </Link>
                </Dropdown.Item>
            ))}
        </Dropdown>
    )
}

export default Deals