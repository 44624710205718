import { Box, Divider, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ajaxService from "../services/ajax-service";
import { ImageURL } from "../constants";
import { ProductSlider, Content, Description, Popular, BrandBanner } from "../components";
import ProductViewSkeleton from "../components/Product/Skeleton";

const Product = () => {
  const { slug } = useParams();
  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState({});
  const [images, setImages] = useState([]);

  const loadData = async (slug) => {
    try {
      const response = await ajaxService.get(`/product/single-product/${slug}`);
      if (response.success) {
        setProduct(response.data);
        setImages(response.data.images.map((i) => ImageURL + i));
        setLoading(false);
      }
    } catch (error) {
      console.error("Error loading product data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData(slug);
  }, [slug]);

  return (
    <Box className="w-full h-auto bg-white m-0 p-0">
      {loading ? (
        <ProductViewSkeleton />
      ) : (
        <Grid container className="pt-16 px-5 sm:p-16 sm:pb-0">
          <Grid
            item
            sm={4}
            xs={12}
            className="animate-fadeInLeft "
            sx={{
              animationDuration: "0.6s", // Faster
              animationDelay: "0.2s",
              animationFillMode: "forwards",
            }}
          >
            <ProductSlider images={images} />
          </Grid>
          <Grid
            item
            sm={7}
            xs={12}
            className="pt-6 sm:pt-0 animate-fadeInLeft"
            sx={{
              animationDuration: "0.8s", // Medium speed
              animationDelay: "0.4s",
              animationFillMode: "forwards",
            }}
          >
            <Box className="sticky top-5">
              <Content product={product} />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            className="animate-fadeInLeft"
            sx={{
              animationDuration: "1s", // Slower
              animationDelay: "0.6s",
              animationFillMode: "forwards",
            }}
          >
            <Description
              description={product?.description}
              specifications={product?.specifications}
              brand={product?.brand}
              datasheet={product?.datasheet}
            />
          </Grid>
          <Grid
            item
            xs={12}
            className="animate-fadeInLeft"
            sx={{
              animationDuration: "1.2s", // Slowest
              animationDelay: "0.8s",
              animationFillMode: "forwards",
            }}
          >
            <Divider className="bg-black mt-16" />
          </Grid>
        </Grid>
      )}

      <Grid
        item
        xs={12}
        className="animate-fadeInLeft"
        sx={{
          animationDuration: "1s", // Slower for this section
          animationDelay: "1s",
          animationFillMode: "forwards",
        }}
      >
        <Popular />
      </Grid>

      <Grid
        item
        xs={12}
        className="animate-fadeInLeft"
        sx={{
          animationDuration: "1.2s", // Slowest for this section
          animationDelay: "1.2s",
          animationFillMode: "forwards",
        }}
      >
        <BrandBanner />
      </Grid>
    </Box>
  );
};

export default Product;
