import React, { useState, useEffect, useContext } from "react";
import { Box, Badge, Button, Divider, IconButton, Typography } from "@mui/material";
// import { Add, Remove } from "@mui/icons-material";
import CloseIcon from '@mui/icons-material/Close';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import { CircularProgress } from "@mui/material";
import { Link } from "react-router-dom";
import { AuthContext } from "../../AuthContext";
import axios from "axios";
import { BASE_URL, ImageURL } from "../../constants";
import { useCart } from "react-use-cart";
import { Add, Remove } from "@mui/icons-material";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

const DrawerContent = ({ setOpen }) => {
    const { CartId } = useContext(AuthContext);
    // const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const { totalUniqueItems, cartTotal, items, updateItemQuantity, emptyCart, isEmpty } = useCart();

    // useEffect(()=> {
    //     if(CartId) {
    //         setLoading(true)
    //         axios.post(`${BASE_URL}/api/v1/carts`, {
    //             cart_id: CartId,
    //             type: ''
    //         },{
    //             headers: {
    //                 Authorization: `Bearer ${localStorage?.getItem('token')}`
    //             },
    //         })
    //         .then((res) => {
    //             const products = res?.data?.cart_items.map((item) => {
    //                 return {
    //                     cart_id: item?.id,
    //                     product: item?.product,
    //                     quantity: item?.quantity
    //                 };
    //             });
    //             setItems(products)
    //             setLoading(false)
    //         })
    //         .catch((error) => {
    //           console.error("Error:", error);
    //           setLoading(false)
    //         });
    //     }
    // }, [CartId])

    const Header = (
        <>
            <Box className="p-5 flex justify-between">
                <Box className="flex items-center">
                    <ShoppingCartCheckoutIcon className='text-[#2858a3]' style={{ fontWeight: 900, fontSize: '2.5em' }} />
                    <p className="font-bold text-lg ml-2">{totalUniqueItems} Item(s)</p>
                </Box>
                <IconButton onClick={() => setOpen(false)}>
                    <CloseIcon />
                </IconButton>
            </Box>
            <Divider />
        </>
    )

    const Footer = (
        <>
             <Divider />
            <Box className="py-3 px-4 flex justify-between items-center">
                <p className="text-sm truncate" style={{ width: "126.5px" }}>Sub Total</p>
                <p className="text-sm">AED {cartTotal.toFixed(2)}</p>
            </Box>
            <Divider />
            <Box className="p-5 flex justify-between">
                <Button component={Link} to="/checkout" onClick={() => setOpen(false)} variant='contained' className="w-full text-white font-bold bg-[#2858A3]">
                    Checkout
                </Button>
            </Box>
        </>
    )

    const content = (
        <>
            {
                items.map((row, index) => (
                    <div key={index}>
                        <Box className="py-3 px-4 flex justify-between items-center">
                            <IconButton size="small" className="bg-[#2858A3]" onClick={() => updateItemQuantity(row.id, row.quantity - 1)}>
                                <Remove className="text-sm text-white" />
                            </IconButton>
                            <Badge badgeContent={row.quantity} color="primary">
                                <img src={`${ImageURL}${row?.img}`} className="h-[70px] w-[70px]" />
                            </Badge>
                            <IconButton size="small" className="bg-[#2858A3]" onClick={() => updateItemQuantity(row.id, (row.quantity + 1) > row.stocks ? row.stocks : (row.quantity + 1))}>
                                <Add className="text-sm text-white" />
                            </IconButton>
                            <Box className="text-[12px] truncate w-36">
                                <p>{row?.name}</p>
                            </Box>
                            <Box className="text-sm">
                                <p className="text-[#2858A3]">{row.itemTotal.toFixed(2)} {row?.product?.currency ? row?.product?.currency : 'AED'}</p>
                            </Box>
                        </Box>
                        <Divider />
                    </div>
                ))
            }
        </>
    )


    return (
        <Box
            className="h-full flex flex-col"
            sx={{
                width: {
                    xs: '100%', // Full width on mobile
                    sm: '100%', // Full width on small devices
                    md: '430px', // Specific width on medium and above
                },
            }}
            role="presentation"
        >
            {Header}
            {!isEmpty && (
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={emptyCart}
                    className="m-2"
                    startIcon={<DeleteOutlineIcon />}
                >
                    Empty Cart
                </Button>
            )}

            <Box className="flex-1 overflow-auto px-4">
                {
                    loading ?
                        <div className="flex justify-center items-center h-full">
                            <CircularProgress size={150} />
                        </div> :
                        !loading && items?.length === 0 ?
                            <Typography className="poppins font-semibold uppercase text-lg text-black flex justify-center items-center h-full">
                                Your Cart is Empty
                            </Typography> :
                            <> {content} </>
                }
            </Box>
            {!isEmpty && Footer}
        </Box>
    )
}

export default DrawerContent